import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
//Cookie
import { getCookie, setCookie } from 'src/webapp/config/cookie';
//Hook
import useComnHook from "src/webapp/hooks/comn/comn.hook";
import useRefreshAccessTokenHook from "src/webapp/hooks/useRefreshAccessTokenHook";

const useUserHook = () => {
    //navigator
    const navigator = useNavigate();
    //중복클릭방지
    const isLock = useRef(false);
    //Hook
    const { refreshTokenExpires, accessTokenExpires } = useComnHook();//토큰 만료설정

    /* ===== 페이지이동 ===== */
    //쿠키존재여부확인
    const pageLoadHandler = () => {
        if (getCookie('NIDRP')) {
            const nav = "/dashboard"
            navigator(nav, { replace: true });
        }
    }
    /* ===== 페이지이동 ===== */

    /* ===== Login ===== */
    //비밀번호 엔터버튼이벤트
    const onhandleOnKeyPress = (e) => {
        if (e.key == "Enter") { loginAPI(); }//로그인API
    }
    
    //로그인폼
    const loginRef = useRef([]);
    //로그인API
    const { partnersInfoAPI } = useRefreshAccessTokenHook();
    const loginAPI = useCallback( async() => {
        if(isLock.current) { return; }

        isLock.current = true;
        const DATA = { "PARTNER_ID":loginRef.current[0].value, "PASS_WD":loginRef.current[1].value };
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnerLoginForPartner.do`, DATA)
            .catch(err => {  console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                //비정상업체
                let msg = "";
                if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if(res.message == "INVALID_DATA") { console.log("데이터가 존재하지 않습니다"); }
                if(res.message == "LOGIN_FAILED") { msg = "정확한 로그인 정보를 입력해 주십시오"; }
                if(res.message == "WAITING_SIGNUP") { msg = "회원가입 승인 대기중입니다"; }
                if(res.message == "FORBIDDEN_DORMANT_OR_WITHDRAWAL_CUSTOMER") { msg = "접속제한 또는 탈퇴한 회원입니다"; }
                if(res.message == "FAILED_DB_PROCESSING") { msg = "잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"; }
                if(res.message == "NOT_REGISTERED_LISENCE") { msg = "파트너스 구독이 만료되었습니다.\n구독 연장 후 서비스를 다시 정상적으로 이용하실 수 있습니다."; }
                if(msg != "") { alert(msg); }
                //정상업체&라이센스만료업체
                if (res.message == "NOT_REGISTERED_LISENCE" || res.message == "SUCCESS") {
                    const accessExpires = accessTokenExpires();//엑세스토큰
                    const refreshExpires = refreshTokenExpires();//리프레시토큰
                    setCookie("NIDAP", res.data.ACCESS_TOKEN, { expires: accessExpires });
                    setCookie("NIDRP", res.data.REFRESH_TOKEN, { expires: refreshExpires });
                    partnersInfoAPI(res.data.COMN_CD);//회원정보호출
                    navigator("/dashboard", { replace: true });
                }
            });  
       
        setTimeout(function() { isLock.current = false; }, 3000);
    })
    /* ===== Login ===== */

    return { pageLoadHandler, onhandleOnKeyPress, loginAPI, loginRef
    }

}

export default useUserHook;