import React, { useState, useCallback, useRef } from "react";
import axios from "axios";
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useMypageHook from 'src/webapp/hooks/mypage/mypage.hook';

const useVehicleRegHook = () => {
    //Store
    const { partner } = usePartnerStore();
    const isLock = useRef(false);    

    /* ===== 공통 =====*/
    //const { isOpen, setOpen } = useVehicleStore();
    const { vehicleGroupListAPI } = useMypageHook();
    const [ isOpen, setOpen ] = useState({init: false, brand: false, model: false, success: false});
    //팝업화면전환
    const changeOpenHandler = (name) => {
        let temp = {};
        for(let prop in isOpen) {
            let value = false;
            if(prop == name) { value = true; }
            temp[prop] = value;
        }
        setOpen(temp);
    }
    //팝업off핸들러
    const onDismissHandler = (name) => { changeOpenHandler(name); }
    /* ===== 공통 =====*/

    /* ===== 브랜드선택 ===== */
    //const { setBrandList } = useBrandStore();
    const [ brandList, setBrandList ] = useState([]);
    const [ page, setPage ] = useState(0);
    //브랜드리스트API
    const brandListAPI = async () => {
        const DATA = { "BRAND_CD":page };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}brandMasterListForJson.do`, DATA)
                .catch(err => { console.log("Error >>", err); reject(err); })
                .then(response => {
                    const res = response.data;
                    if(res.message != "SUCCESS") { setBrandList([]); }
                    if(res.message == "SUCCESS") { 
                        setBrandList(brandList.concat(res.data));
                        setPage(res.data.length >= 20 ? res.data[res.data.length - 1].BRAND_CD : null);
                    }
                    resolve(true);
                });
        })
    }

    //브랜드선택
    const [ brand, setBrand ] = useState('');
    const setBrandHandler = (item) => { setBrand(item); }
    const [ brandItem, setBrandItem ] = useState({});
    const handleBrandChange = (data) => { setBrandItem(data); }
    /* ===== 브랜드선택 ===== */

    /* ===== 모델선택 ===== */
    //브랜드모델리스트API
    //const { setModelList } = useBrandStore();
    const [ modelList, setModelList ] = useState([{MODEL_KOR_NAME: "전체선택", value:1}]);
    const modelListAPI = async (brand) => {
        const DATA = { "BRAND_CD":brand.BRAND_CD, "PARTNER_CD":partner.COMN_CD, "ROWNUM":page };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}brandItemListForPartner.do`, DATA)
            .catch(err => { console.log("Error >>", err); reject(err); })
            .then(response => {
                const res = response.data;
                if(res.message != "SUCCESS") { setModelList([]); }
                if(res.message == "SUCCESS") { 
                        if(checkedList[0] && checkedList[0].value == 1) {
                            setCheckedList(checkedList.concat(res.data));
                        }
                        setModelList(modelList.concat(res.data));
                        setPage(res.data.length >= 20 ? (page + 1) : null);
                    }
					resolve(true); 
				});
		})
    }

    //체크박스제어
    const [ checkedList, setCheckedList ] = useState([]);
    const setModelHandler = useCallback((checked, item, model) => {
        if (item.MODEL_KOR_NAME == "전체선택"){
            if(checked){
                setCheckedList(model)
            }else{
                setCheckedList([]);
            }
            return;
        }
        if(checked) { setCheckedList([...checkedList, item]); } 
        else { setCheckedList(checkedList.filter((el) => el != item && el.value != 1));} 
    }, [checkedList]);
    /* ===== 모델선택 ===== */

    /* ===== 차량등록 ===== */
    //차량등록API
    const vehicleRegAPI = async (data) => {
        if(isLock.current) { return; }
        isLock.current = true;
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerVehicleRegForPartner.do`, data)
                    .catch(err => { console.log("Error >>", err); reject(err); })
                    .then(response => {
                        const res = response.data;
                        if(res.message == "SUCCESS") { }
                        resolve(true);
                    });
            setTimeout(function() { isLock.current = false; }, 3000);
        })
    }
    /* ===== 차량등록 ===== */

    return { isOpen, changeOpenHandler, onDismissHandler//공통
        , page, brandList, brandListAPI, brand, setBrandHandler, brandItem, handleBrandChange//브랜드선택
        , modelList, modelListAPI, checkedList, setCheckedList, setModelHandler//모델선택
        , vehicleRegAPI //취급차량등록
    };
}

export default useVehicleRegHook;