import React, { useCallback, useState, useRef } from 'react';
import axios from "axios";
//Store
import usePartnerStore from "src/webapp/stores/partner.store";
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const useItemHook = () => {
    //Hook
    const { excelExportAPI } = useComnHook();
    //Store
    const { partner } = usePartnerStore();
    //const { setItemList } = useItemStore();

    /* ===== 아이템리스트 ===== */
    //셀렉트옵션
    const [option, setOption] = useState(1);
    const optionList = [{ name: "전체", value: 1 }
        , { name: "엔진오일", value: 2 }
        , { name: "타이어", value: 3 }
        , { name: "정기점검", value: 4 }
        , { name: "기타", value: 5 }];

    //카테고리별아이템리스트API
    const [itemList, setItemList] = useState([]);
    const itemListCategoryAPI = useCallback(async () => {
        const DATA = { "PARTNER_CD": partner.COMN_CD };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerItemListCategoryForPartner.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then(response => {
                    if (!response) { return resolve([]); }
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message != "SUCCESS") { resolve([]); }
                    if (res.message == "SUCCESS") { itemListGroup(res.data); resolve(res.data); }
                });
        })
    });
    //카테고리별그룹화함수
    const itemListGroup = (data) => {
        const array = data, hash = Object.create(null), grouped = [];
        array.forEach(function (o, i) {
            const key = ['CATEGORY'].map(function (k) { return o[k]; }).join('|');
            if (!hash[key]) {
                hash[key] = { category: o.CATEGORY, itemGroup: [] };
                grouped.push(hash[key]);
            }
            hash[key]['itemGroup'].push(o);
        });
        setItemList({ data: grouped, size: data.length });
    }

    //엑셀다운로드
    const excelExportHandler = useCallback(async () => {
        const data = { "PARTNER_CD": partner.COMN_CD, "CATEGORY": (option - 1) };
        const url = `${process.env.REACT_APP_BACK_HOST}partnerItemListExcel.do`;
        const filename = `[두바키 파트너스]가격표`;

        excelExportAPI(data, url, filename);//엑셀다운로드API
    });
    /* ===== 아이템리스트 ===== */

    return {
        optionList, option, setOption//아이템리스트
        , excelExportHandler//엑셀다운로드
        , itemList, setItemList, itemListCategoryAPI, itemListGroup//등록아이템
    };
}

export default useItemHook;