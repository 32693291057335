import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useNavBarHook = () => {

    //nav메뉴
    const [nav] = useState([
        {label: '대시보드',     icon:'chart-simple',    nav:`/dashboard`},
        {label: '알림',         icon:'bell',           nav:`/notify`},
        {label: '매출 관리',    icon:'chart-area',      nav:`/sales`},
        {label: '스케줄 관리',  icon:'calendar-days',   nav:`/schedule`},
        {label: '가격표 관리',  icon:'calculator',      nav:`/item`}, 
        {label: '회원 관리',    icon:'users',           nav:`/members`},
        {label: '정산관리',     icon:'user',            nav:`/calc`},
        {label: '마이페이지',   icon:'coins',           nav:`/mypage`}
    ]);

    //페이지이동
    const navigator = useNavigate();
    const navHandler = (item) => {
        navigator(item);
    }

    return { navHandler, 
        nav };
}

export default useNavBarHook;