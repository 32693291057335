import React, { useEffect, Suspense, lazy } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss'
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _titleArea = lazyWithPreload(() => import('../../_titleArea'));

export default function AuthTel() {

	//Hook
	const { compPreload } = useComnHook();
	const { checkedStorage //스토리지유무확인
		, validatedTelHandler //이메일유효성검사
		, onhandleOnKeyPress//엔터이벤트
		, telNo//입력휴대폰번호
		, signupTelCertSendAPI //버튼클릭(휴대폰메세지전송)
		, validated//유효성확인객체useState
		, signupRef//회원가입Ref
	} = useSignupHook();

	//useEffect
	useEffect(() => {
		const comp = [_titleArea];
		compPreload(comp);//Preload

		checkedStorage();//쿠키유무확인
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}>
						<_titleArea usePrev={true} title={`휴대폰번호로 인증하기`} subtitle={`인증 코드를 받으실 휴대폰 번호를 입력해주세요`} />
					</Suspense>

					{/* 입력필드 */}
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.inputField}><label>휴대폰 번호</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => validatedTelHandler(e)}
									onKeyDown={(e) => onhandleOnKeyPress(e, 4)}
									ref={el => (signupRef.current[0] = el)}
									minLength={10} maxLength={11}
									type="text" inputMode="numeric" placeholder="-을 제외한 숫자만 입력해주세요" />
								<label className="validateMsg">{validated.msg}</label>
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => signupTelCertSendAPI()}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">인증번호 보내기</button>
					</div>
				</div>
			</section>
		</>
	);
}