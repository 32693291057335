import React, { useLayoutEffect, Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';
//image
import verifyIcon_Img from 'src/asset/images/icon/common-verify.webp';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
//Comp
import _titleArea from '../../_titleArea';

export default function Auth() {
    //navigator
    const navigator = useNavigate();
    //Hook
    const { imgPreload//preload
    } = useComnHook();
    //useEffect
    useLayoutEffect(() => {
        const images = [verifyIcon_Img];
        imgPreload(images);//preload
    }, []);

    return (
        <>
            <section className={["container", styles.userContainer].join(" ")}>
                <div className={styles.wrapper}>
                    <_titleArea usePrev={true} title={`간편인증`} subtitle={`인증 방법을 선택해주세요`} />
                    <div className={"flickPanel"}>
                        <div className={styles.mobileImgArea}><img src={verifyIcon_Img} /></div>
                    </div>
                    <div className={["fixedPanel", styles.btnArea].join(" ")}>
                        <Link to={"/signup/auth/email"} className="submitButton">이메일 인증하기</Link>
                        {/* <Link to={"/signup/auth/tel"} className="submitSecButton">전화번호 인증하기</Link> */}
                    </div>
                </div>
            </section>
        </>
    );
}