import React from 'react'
import { useNavigate } from 'react-router-dom';

//style
import styles from 'src/asset/scss/component/statusBar.module.scss'
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

export default function StatusBar(props) {

    const { FontAwesomeIcon } = useComnHook();
    const navigator = useNavigate();

    //function
    //이전페이지로이동
    const prevPageHandler = () => {
        navigator(-1, { replace:true });
    }

	return (
		<>
        <div className={styles.statusBar}>
            {/* 이전페이지로 */}
            <div className={styles.prevBtnArea}>
                {props.usePrev ? 
                    <button onClick={prevPageHandler}><FontAwesomeIcon icon={['fal', 'arrow-left']}  /></button> 
                : null}
            </div>
            <div></div>
            <div></div>
        </div>
		</>
	);
  }