import React, { useEffect, useRef, Suspense, lazy } from 'react';

//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _header = lazyWithPreload(() => import('src/webapp/views/email/header'));
const _footer = lazyWithPreload(() => import('src/webapp/views/email/footer'));
const _content = lazyWithPreload(() => import('./_emailContent'));

export default function _emailForm() {

	//useRef
	const emailForm = useRef();

	//style
	const pointColor = "#35B6FF";
    //const pointColor = "#FFD400";

	//Hook
	const { compPreload } = useComnHook();

	//useEffect
	useEffect(() => {
		const comp = [_header, _footer, _content];
		compPreload(comp);//Preload
	}, []);

	//function
	//이메일전송
	const sendEmail = (e) => { e.preventDefault(); };

	return (
		<form ref={emailForm} onSubmit={sendEmail}>
			<div style={{
				widows: "2",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				fontFamily: "돋움, Dotum, Helvetica, sans-serif",
				width: "800px",
				padding: "20px 0",
				backgroundColor: "#F8F9F8",
				textTransform: "none",
				textIndent: "0",
				letterSpacing: "normal",
				wordSpacing: "0",
				fontStyle: "normal",
				fontWeight: "normal",
				fontSize: "12px",
				color: "rgb(0, 0, 0)",
				whiteSpace: "normal",
				orphans: "2",
				fontVariantLigatures: "normal",
				fontVariantCaps: "normal",
				WebkitTextStrokeWidth: "0px",
			}}>
				<div style={{
					width: "100%",
					maxWidth: "586px",
					padding: "25px 50px 0 50px",
					background: "#FFF",
					letterSpacing: "-0.2px",
				}}>
					<Suspense fallback={null}><_header h1={`회원가입을 위한 `} em={`인증번호`} h2={`를`} h3={`안내 드립니다.`} /></Suspense>
				</div>
				<div style={{
					width: "100%",
					maxWidth: "586px",
					padding: "25px 50px 19px 50px",
					background: "#FFF",
					letterSpacing: "-0.2px",
				}}>
					<Suspense fallback={null}><_content /></Suspense>
				</div>
				<div style={{
					width: "100%",
					maxWidth: "686px",
					background: "#F8F9F8",
					letterSpacing: "-0.2px",
				}}>
					<Suspense fallback={null}><_footer /></Suspense>
				</div>
			</div>
		</form>
	);
}