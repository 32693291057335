import React from 'react';
import { Link } from 'react-router-dom';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';

export default function Login() {

    return (
        <>
        <Link to={"/signup"} 
            className={["submitSecButton", styles.signupBtn].join(" ")}>회원가입하기</Link>
        <div className={styles.navBtnArea}>
            <div>
                <Link to={"/find/id"}>아이디 찾기</Link>
                <Link to={"/find/passwd"}>비밀번호 찾기</Link>
            </div>
            <Link to={"/signup"} className={styles.signupBtn}>회원가입</Link>
        </div>
        </>
    );
}