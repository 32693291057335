import { create } from "zustand";

const usePartnerStore = create((set) => ({
    isState : false,//토큰호출성공여부
    partner: {},//파트너로그인정보(PARTNER_CD, PARTNER_ID)
    info: {},//파트너기본정보
    idData: {},//파트너찾기데이터 - 아이디
    pwData: {},//파트너찾기데이터 - 비밀번호
    emailFormMessage: '',//파트너찾기데이터 - 비밀번호
    //===== 공지사항 =====//
    boardList: [],//공지사항리스트

    setState: (isState) => set((state) => ({...state, isState})),
    setPartner: (partner) => set((state) => ({...state, partner})),
    setInfo: (info) => set((state) => ({...state, info})),
    setBoardList: (boardList) => set((state) => ({...state, boardList})),
    setBoardList: (boardList) => set((state) => ({...state, boardList})),
    setIdData: (idData) => set((state) => ({...state, idData})),
    setPwData: (pwData) => set((state) => ({...state, pwData})),
    setEmailFormMessage: (emailFormMessage) => set((state) => ({...state, emailFormMessage})),
}));

export default usePartnerStore;