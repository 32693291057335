import React, { useEffect, Suspense, lazy } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss'
//comp
import _emailForm from '../email/_emailForm';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';

export default function AuthEmail() {
	//Hook
	const { checkedStorage //스토리지유무확인
		, validatedEmailHandler //이메일유효성검사
		, onhandleOnKeyPress//엔터이벤트
		, signupEmailCertSendAPI //버튼클릭(이메일전송)
		, validated//유효성확인객체useState
		, emailForm//이메일폼
		, signupRef//회원가입Ref
	} = useSignupHook();
	//useEffect
	useEffect(() => {
		checkedStorage();//쿠키유무확인
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					<_titleArea usePrev={true} title={`이메일로 인증하기`} subtitle={`인증 코드를 받으실 이메일 주소를 입력해주세요`} />{/* 타이틀 */}
					{/* 입력필드 */}
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.inputField}><label>이메일</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => validatedEmailHandler(e)}
									onKeyDown={(e) => onhandleOnKeyPress(e, 3)}
									ref={el => (signupRef.current[0] = el)}
									type="text" inputMode="email"
									placeholder="이메일을 입력해주세요" />
								<label className="validateMsg">{validated.msg}</label>
							</div>
						</div>
					</div>
					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => signupEmailCertSendAPI(1, signupRef.current[0].value)}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">인증번호 보내기</button>
					</div>
				</div>
			</section>
			{/* 이메일 폼 */}
			{<div className="emailForm" ref={emailForm}><_emailForm /></div>}
		</>
	);
}