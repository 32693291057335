import React, { useEffect, Suspense } from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';
import __step from './step';

export default function Step04() {
	//Hook
	const { comnList2//공통코드useState
		, comnListAPI//공통코드리스트
	} = useComnHook();
	const { checkedStorage //스토리지유무확인
		, changeMechanicHandler//정비인원선택
		, pageOnLoadHandler //버튼클릭
		, signupRef//회원가입Ref
		, validated
		} = useSignupHook();
	//useEffect
	useEffect(() => {
		checkedStorage();//쿠키유무확인
		comnListAPI("P");//공통코드리스트API
	}, []);

	return (
		<>
		<section className={["container", styles.userContainer].join(" ")}>
            <div className={styles.wrapper}>
				<_titleArea usePrev={true} title={`업체 등록하기`} subtitle={`매장 내 정비가능 인원을 선택해주세요`} isMobile={true} />{/* 타이틀 */}
				<__step step04={true} idx={4} />{/* 스텝 */}
				{/* 셀렉트필드 */}
				<div className={["flickPanel", styles.inputArea].join(" ")}>
					<div className={styles.inputField}><label>정비인원</label>
						<div className="select">
							<SelectSearch onChange={(e) => {changeMechanicHandler(); (signupRef.current[0].value = e)}} 
							ref={el => (signupRef.current[0] = el)}
									options={comnList2} value={comnList2.value}
									placeholder='정비인원'/>
						</div>
					</div>
				</div>

				{/* 버튼 */}
				<div className={["fixedPanel", styles.btnArea].join(" ")}>
					<button onClick={() => pageOnLoadHandler(9)}
						disabled={validated.key} 
						className="submitButton">다음</button>
				</div>
			</div>
        </section>
		</>
	);
  }