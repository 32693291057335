import { create } from "zustand";

const useSalesStore = create((set) => ({
    salesCnt: {},//매출관리-업체매출통계
    salesList: [],//매출관리-업체매출리스트
    salesDetail: {},//매출관리-매출상세정보
    isOpen: false,//매출관리-매출상세정보팝업여부

    setSalesCnt: (salesCnt) => set((state) => ({...state, salesCnt})),
    setSalesList: (salesList) => set((state) => ({...state, salesList})),
    setSalesDetail: (salesDetail) => set((state) => ({...state, salesDetail})),
    setOpen: (isOpen) => set((state) => ({...state, isOpen})),
}));

export default useSalesStore;