import React, { useEffect } from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';
import __step from './step';
import AttachFile from 'src/webapp/views/component/AttachFile';
import Spinner from 'src/webapp/views/component/loading/Spinner';

export default function Step06() {
	//Hook
	const { getStorage } = useComnHook();
	const { checkedStorage //스토리지유무확인
		, handleFile1Change//사업자등록증이미지데이터끌어올리기
		, handleFile2Change//업체이미지데이터끌어올리기
		, partnerMasterRegAPI//회원가입
		, files1//사업자등록증이미지useState
		, files2//업체외관이미지useState
		, isSubmit
	} = useSignupHook();
	//useEffect
	useEffect(() => {
		checkedStorage();//쿠키유무확인
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					<_titleArea usePrev={true} title={`업체 등록하기`} subtitle={`업체 외관 및 사업자 등록증을 업로드 해주세요`} isMobile={true} />{/* 타이틀 */}
					<__step step06={true} idx={6} />{/* 스텝 */}

					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.attachFileArea}>
							<div><AttachFile onData={handleFile2Change} file={files2} cnt={1} label={`사업자 등록증 업로드`} /></div>
							<div><AttachFile onData={handleFile1Change} file={files1} cnt={3} label={`업체 외관사진 업로드`} /></div>
						</div>
					</div>
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => !isSubmit ? partnerMasterRegAPI() : null}
							disabled={files1.length === 0 || files2.length === 0 ? true : false}
							className="submitButton">{isSubmit ? <Spinner label={'잠시만 기다려주세요'} /> : '가입하기'}</button>
					</div>
				</div>
			</section>
		</>
	);
}