import React, { useEffect, Suspense, lazy, useCallback, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useDatepickerHook from 'src/webapp/hooks/component/datepicker.hook';
import useCalcHook from 'src/webapp/hooks/calc/calc.hook';
//Comp
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import Spinner from 'src/webapp/views/component/loading/Spinner';//로딩바
const _calc  = lazy(() => import('./comp/calc'), { ssr: false });//정산결과
//bottomsheet
const _bsRangeDatepicker  = lazy(() => import('src/webapp/views/component/BsRangeDatepicker'), { ssr: false });//날짜검색

export default function Calc() {
    //Store
    const { isState } = usePartnerStore();
    //Hook
    const { initDateHandler
        , rangeIsOpen//날짜선택팝업useState
        , onRangeOpenHandler//날짜선택팝업on
        , handleRangePickerChange//날짜선택팝업off
        , rangeDate//선택된날짜useState 
    } = useDatepickerHook();//날짜팝업
    const { calcListAPI//정산리스트API
        , excelExportHandler//엑셀다운로드API
        , setTotalPrice//정산총액
    } = useCalcHook();
    //데이터로딩
    const [isPending, setPending] = useState(true);
    const [ calcList, setCalcList ] = useState([]);//정산리스트
     //useEffect
     useEffect(() => {
        initDateHandler(-7, 0);//날짜초기셋팅(7일전~당일)
    }, []);
    useEffect(() => {
        if(isState && rangeDate != false){
            setPending(true);
			Promise.all([ calcListAPI(rangeDate) ]).then((value) => {
                setCalcList(value[0]);
				setPending(false);
			});
        }
	}, [isState, rangeDate]);

    return (
        <>
        <section className="fixedPanel container main">
            <NavBar id={6} />{/* 메뉴바 */}
            <div className="layer">
                <div className="fixedPanel">
                    <TitleBar title={`정산 관리`} />{/* 타이틀 */}
                    {/* 검색창 */}
                    <hr className="separate" />
                    <div className="searchArea">
                        <div className="dateArea">
                            <div className="datepicker">{(rangeDate.startDate ?? '') + ` - ` + (rangeDate.endDate ?? '')}</div>
                            <button onClick={(e) => onRangeOpenHandler()}
                                className="btnCalendar"><FontAwesomeIcon icon={['fal', 'calendar-lines']} /></button>
                            <button onClick={(e) => excelExportHandler(rangeDate)}
                                className="btnExcel" ><FontAwesomeIcon icon={['fal', 'arrow-down-to-line']} /></button>
                        </div>
                        <div>
                            <div className="cntArea">
                                <em><span>총 정산액</span> {`${setTotalPrice(calcList)} 원`}</em>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flickPanel">
                    {isPending ? 
                        <div className="fullHeight"><Spinner isPending={true} /></div> 
                    : 
                        <Suspense fallback={null}>{!isPending ? <_calc list={calcList} /> : null}</Suspense>
                    }
                </div>
            </div>
        </section>

        {/* bottomSheet-datepicker */}
        {rangeIsOpen ? <Suspense fallback={null}><_bsRangeDatepicker isOpen={rangeIsOpen} onDismiss={handleRangePickerChange} /></Suspense> : null}
        </>
    );
}