import { create } from "zustand";

const useComnStore = create((set) => ({
    isOpen: false,//공통팝업오픈여부
    bottomsheet : {isOpen:false},
    datepicker : {isOpen:false, startDate:'', endDate:''},//캘린더-오픈여부, 시작일, 종료일
    socket : { message:'' },//소켓메세징
    comnList: [], //공통코드리스트
    isLoad: false, //로드대기상태저장
    opt: [{}],//옵션리스트
    movePage: false,//페이지 이동 감지
    isPlaying: false,//예약완료효과음
    sound: false,//예약변경효과음
    
    setIsOpen: (isOpen) => set((state) => ({...state, isOpen})),
    setBottomsheet: (bottomsheet) => set((state) => ({...state, bottomsheet})),
    setDatepicker: (datepicker) => set((state) => ({...state, datepicker})),
    setSocket: (socket) => set((state) => ({...state, socket})),
    setComnList: (comnList) => set((state) => ({...state, comnList})),
    setIsLoad: (iseLoad) => set((state) => ({...state, iseLoad})),
    setOpt: (opt) => set((state) => ({...state, opt})),
    setMovePage: (movePage) => set((state) => ({...state, movePage})),
    setPlaying: (isPlaying) => set((state) => ({...state, isPlaying})),
    setSound: (sound) => set((state) => ({...state, sound})),
}));

export default useComnStore;