import React, { useEffect, Suspense, useState, useCallback, lazy } from 'react';
import useScript from 'react-script-hook/lib/use-script';
//style
import styles from 'src/asset/scss/style/mypage.module.scss'
//Hooks
import useAuthHook from 'src/webapp/hooks/auth/auth.hook';
import useMypageHook from 'src/webapp/hooks/mypage/mypage.hook';
import useMyPageNoticeHook from 'src/webapp/hooks/mypage/mypageNotice.hook';
//Stores
import usePartnerStore from 'src/webapp/stores/partner.store';
//Comp
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import _profile from './comp/profile/Profile';//프로필정보&수정
const _vehicleList    = lazy(() => import('./comp/VehicleList'), { ssr: false });//등록차량
const _noticeList     = lazy(() => import('./comp/NoticeList'), { ssr: false });//공지사항
const _button = lazy(() => import("./comp/Button"), { ssr: false });//버튼목록

export default function Mypage() {
    //Script Load
    useScript({ src: 'https://t1.kakaocdn.net/kakao_js_sdk/2.4.0/kakao.min.js', onload: () => console.log('kakao Min Loaded!'), });
    //Hooks
    const { onLogoutHandler } = useAuthHook();
    const { addKakaoChannel//카카오채팅API연결
        , vehicleGroupListAPI//브랜드별취급차량리스트API
    } = useMypageHook();
    const { boardListAPI//공지사항리스트API
    } = useMyPageNoticeHook();
    //Stores
    const { isState } = usePartnerStore();
    //Promise-Callback
    //데이터로딩
    const [isPending, setPending] = useState(true);
    const [ vehicleGroupList, setVehicleGroupList ] = useState([]);
    // const VehicleListComp = () => { return !isPending ? <_vehicleList isPending={isPending} list={vehicleGroupList} /> : null; }
    const [ boardList, setBoardList ] = useState([]);
    // const BoardListComp = () => { return !isPending ? <_noticeList isPending={isPending} list={boardList} /> : null; }
    //useEffect
    useEffect(() => {
        setPending(true);
        if(isState) {
            Promise.all([ boardListAPI(), vehicleGroupListAPI() ]).then((value) => {
                setBoardList(value[0]);
                setVehicleGroupList(value[1]);
                setPending(false);
            });
        }
    }, [isState]);

    return (
        <>
        <section className="fixedPanel container main">
            <NavBar id={7} />{/* 메뉴바 */}
            <div className="layer">
                <div className="fixedPanel">
                    <TitleBar title={`마이페이지`} />{/* 타이틀바 */}
                </div>
                <div className="flickPanel">
                    <div className={styles.btnArea}>
                        <button onClick={(e) => addKakaoChannel()}>고객센터</button>
                        <button onClick={(e) => onLogoutHandler()}>로그아웃</button>
                    </div>
                    <div className={styles.mypageArea}>
                        <hr className="separate" /><_profile />{/* 업체정보 */}
                        <hr className="separate" /><Suspense fallback={null}>{!isPending ? <_vehicleList isPending={isPending} list={vehicleGroupList} /> : null}</Suspense>{/* 등록차량리스트 */}
                        <hr className="separate" /><Suspense fallback={null}>{!isPending ? <_noticeList isPending={isPending} list={boardList} /> : null }</Suspense>{/* 공지사항리스트 */}
                        <hr className="separate" /><Suspense fallback={null}><_button /></Suspense>{/* 버튼리스트 */}
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}