import React, { useEffect, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';

//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//comp
import _emailForm from 'src/webapp/views/user/find/email/_emailForm';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _titleArea = lazyWithPreload(() => import ('../_titleArea'));

export default function _success() {

	//navigator
	const navigator = useNavigate();

	//Store
	const { idData } = usePartnerStore();

	//Hook
	const { compPreload//preload
		, clearStorage//스토리지전체삭제
	} = useComnHook();//preload

	//useEffect
	useEffect(() => {
		const comp = [_titleArea];
		compPreload(comp);//preload

		clearStorage();
	}, []);

	return (
		<>
        <section className={["container", styles.userContainer].join(" ")}>
            <div className={styles.wrapper}>
				{/* 타이틀 */}
				<Suspense fallback={null}><_titleArea title={`아이디찾기 성공`} subtitle={`귀하의 아이디는 다음과 같습니다.`} /></Suspense>

				{/* 입력필드 */}
				<div className={["flickPanel", styles.inputArea].join(" ")}>
					<div className={styles.inputField}><label>아이디</label>
						<div>{idData.PARTNER_ID}</div>
					</div>
				</div>
				
				{/* 버튼 */}
				<div className={["fixedPanel", styles.loginBtnArea].join(" ")}>
                    <button onClick={() => navigator('/login')} 
						className="submitButton">확인</button>
				</div>
			</div>
        </section>
		</>
	);
}