import React, { useState, useRef, useCallback } from 'react';
import axios from "axios";

import useComnStore from "src/webapp/stores/comn.store";
import usePartnerStore from "src/webapp/stores/partner.store";
import useSalesStore from "src/webapp/stores/sales.store";
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const useSalesHook = () => {
    //Store
    const { partner } = usePartnerStore();
    //Hook
    const { replaceDateHandler//날짜포맷(yyyyMMdd)
        , excelExportAPI//엑셀익스포트
    } = useComnHook();

    //매출통계API
    const [ salesCnt, setSalesCnt ] = useState({ CNT:0, TOT_PRICE:0 });
    const salesCntAPI = useCallback( async(date) => {//type-0.첫로딩 or Socket, 1.스크롤
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);
        const DATA = {"PARTNER_CD": partner.COMN_CD, "START_DATE": startDate, "END_DATE": endDate };

        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerSalesCntForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err);})
                .then(response => {
                    const res = response.data;
                    if(res.message == "INVALID_REQUEST") { alert("잘못된 요청입니다"); }
                    if(res.message == "SUCCESS") { setSalesCnt(res.data); resolve(res.data);  }
                    if(res.message != "SUCCESS") { setSalesCnt({ CNT:0, TOT_PRICE:0 }); resolve({ CNT:0, TOT_PRICE:0 }); }
                });
        });
    });

    //업체매출리스트API
    const [ page, setPage ] = useState(0);
    const [ salesList, setSalesList ] = useState([]);
    const salesListAPI = useCallback( async(type, date) => {//type-0.첫로딩 or Socket, 1.스크롤
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);
        //const DATA = {"PARTNER_CD": partner.COMN_CD, "START_DATE":'20230603', "END_DATE":'20240603', "ROWNUM":type = 0 ? 0 : page };
        const DATA = {"PARTNER_CD": partner.COMN_CD, "START_DATE": startDate, "END_DATE": endDate, "ROWNUM":type = 0 ? 0 : page };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerSalesListForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err);})
                .then(response => {
                    const res = response.data;
                    if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if(res.message != "SUCCESS") { setPage(null); resolve([]);  }
                    if(res.message == "SUCCESS") {
                        setSalesList(type == 0 ? res.data : salesList.concat(res.data));
                        setPage(res.data.length >= 20 ? res.data.length + page: null);
                        resolve(res.data);
                    }
                });
        });
    });
    
    //엑셀다운로드
    const excelExportHandler = useCallback( async(date) => {
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);
        const data = { "PARTNER_CD":partner.COMN_CD, "START_DATE":startDate, "END_DATE":endDate };
        const url = `${process.env.REACT_APP_BACK_HOST}partnerSalesListExcel.do`;
        const filename = `[두바키 파트너스]${startDate} - ${endDate}매출 관리`;

        excelExportAPI(data, url, filename);//엑셀다운로드API
    });

    /* ===== 매출회원상세 ===== */
    const [ isOpen, setOpen ] = useState(false);
    //팝업off
    const handleDismissChange = (data) => { setOpen(data); }
    //매출별회원예약정보API
    const [ profile, setProfile ] = useState({});
    const salesMemberProfileAPI = async (item) => {
        const DATA = { "PARTNER_CD":partner.COMN_CD, "ORDER_NO":item.ORDER_NO, "MEMBER_CD":item.MEMBER_CD };
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnerSalesByMemberProfileForPartner.do`, DATA)
            .catch(err => {console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                if (res.message == "INVALID_REQUEST") { alert("잘못된 요청입니다"); }
                if (res.message == "SUCCESS") { setProfile(res.data); setOpen(true); }
            });
    }
    /* ===== 매출회원상세 ===== */

    return { salesCnt, salesCntAPI, salesList, salesListAPI, page//매출통계 및 매출리스트
        , isOpen, handleDismissChange, profile, salesMemberProfileAPI//매출회원상세
        , excelExportHandler//엑셀다운로드
    };
}

export default useSalesHook;