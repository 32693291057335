import React from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useUserHook from 'src/webapp/hooks/user/user.hook';
//Comp
import TitleArea from '../TitleArea';
import _loginButton from './LoginBtnArea';

export default function Login() {
	//Hook
	const { loginRef
		, onhandleOnKeyPress//엔터클릭
		, loginAPI//로그인API
	} = useUserHook();

	return (
		<>
		<section className={["container", styles.userContainer].join(" ")}>
			<div className={styles.wrapper}>
				<TitleArea title={`로그인을 진행해주세요.`} />{/* 타이틀 */}

				{/* 입력필드 */}
				<div className={["flickPanel", styles.inputArea].join(" ")}>
					<div className={styles.inputField}><label>아이디</label>
						<div>
							<input ref={el => (loginRef.current[0] = el)}
								type="text" inputMode="normal" placeholder="아이디를 입력해주세요" />
						</div>
					</div>
					<div className={styles.inputField}><label>비밀번호</label>
						<div>
							<input ref={el => (loginRef.current[1] = el)}
								onKeyDown={(e) => onhandleOnKeyPress(e)}
								type="password" inputMode="normal" placeholder="비밀번호를 입력해주세요" />
						</div>
					</div>
				</div>

				{/* 버튼 */}
				<div className={["fixedPanel", styles.loginBtnArea].join(" ")}>
					<button onClick={(e) => loginAPI() }
						className="submitButton">로그인 하기</button>
					<_loginButton />
				</div>
			</div>
		</section>
		</>
	);
}