import React, { Suspense, useEffect, useState } from 'react';
//styles
import 'src/asset/scss/config/_styles.scss';
import './App.css';
//FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
//Hook
import useAppHook from 'src/webapp/hooks/comn/app.hook';
import useSocketHook from './webapp/hooks/comn/socket.hook';
//Store
import usePartnerStore from './webapp/stores/partner.store';
import useComnStore from './webapp/stores/comn.store';
//pages-init
import Routers from 'src/webapp/views/comn/Route';
import Spinner from './webapp/views/component/loading/Spinner';
//Sound
import reserveSuccess from 'src/asset/sounds/reserveSuccess.mp3';
import reserveUpdate from 'src/asset/sounds/reserveUpdate.mp3';
import useSound from 'use-sound';

//pages/comn
function App() {
  //Hook
  const { resizeHandler//윈도우사이즈변경
  } = useAppHook();
  const { ws, socketConnect } = useSocketHook();
  const { partner, isState } = usePartnerStore();
  const { isPlaying, setPlaying, sound, setSound } = useComnStore();
  //useEffect
  useEffect(() => {
    resizeHandler();//윈도우사이즈변경
    //Fontawesome
    library.add(fat, fal, fas);
  }, []);
  const [ isLock, setLock ] = useState(false);
  useEffect(() => {
    if (isState && !isLock) {
      setLock(true);
      socketConnect();//소켓연결
      setTimeout(()=> {setLock(true);}, 3000);
    }
  }, [ws, partner, isState]);
  //예약완료효과음
  const reserveSuccessSound = new Audio(reserveSuccess);
  // const [reserveSuccessSound] = useSound(reserveSuccess);
  useEffect(() => {
    isPlaying ? reserveSuccessSound.play() : reserveSuccessSound.pause();
    if (isPlaying) {
      // reserveSuccessSound();
      setTimeout(() => {
        setPlaying(false)
      }, 2000)
    }
  }, [reserveSuccessSound, isPlaying]);
  //예약변경효과음
  const reserveUpdateSound = new Audio(reserveUpdate);
  // const [reserveUpdateSound] = useSound(reserveUpdate)
  useEffect(() => {
    sound ? reserveUpdateSound.play() : reserveUpdateSound.pause();
    if (sound) {
      // reserveUpdateSound();
      setTimeout(() => {
        setSound(false)
      }, 2000)
    }
  }, [reserveUpdateSound, sound]);
  return (
    <>
      <Suspense fallback={<Spinner />}><Routers /></Suspense>
    </>
  );
}

export default App;