import React, { useState, useRef } from "react";
import axios from 'axios';

//dateFormat
import dayjs from "dayjs";
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//IMAGE
import onError_Img from 'src/asset/images/no-image.webp';
//secureStorage
import secureLocalStorage from "react-secure-storage";
//Store
import useComnStore from 'src/webapp/stores/comn.store';
import useItemRegUpdateHook from "../item/itemRegUpdate.hook";

const useComnHook = () => {
    const { itemRef } = useItemRegUpdateHook();
    //PopupOpen
    const [isOpen, setOpen] = useState(false);

    //constant
    const { setOpt, setComnList, setDatepicker } = useComnStore();

    //compPreload
    const compPreload = (comp) => {
        comp.forEach((comp) => { comp.preload(); });
    }

    //imagePreload
    const imgPreload = (img) => {
        img.forEach((image) => {
            new Image().src = image;
        });
    }

    //대체이미지
    const onErrorImg = (e) => {
        //e.currentTarget.src = onError_Img;
        return "this.style.display = 'none';"
    };

    //SecureStorage
    const isStorage = (item) => {
        const getData = secureLocalStorage.getItem(item);
        if (getData == null) { return false; }
        else { return true; }
    }
    const setStorage = (item, value) => {
        return secureLocalStorage.setItem(item, value);
    }
    const getStorage = (item) => {
        return secureLocalStorage.getItem(item);
    }
    const updateStorage = (item, key, value) => {
        //secureLocalStorage.getItem(item)[key] = value;
        //return secureLocalStorage.getItem(item);
        const getData = getStorage(item);
        let setData = null;
        if (getData != null) {
            getData[key] = secureLocalStorage.getItem(item)[key] = value;
            setData = getData;
        } else {
            setData = value;
        }
        setStorage(item, setData);
    }
    const removeStorage = (item) => {
        return secureLocalStorage.removeItem(item);
    }
    const clearStorage = () => {
        secureLocalStorage.clear();
    }

    //숫자만입력
    const onNumberHandler = (e) => {
        let value = e.target.value;

        const num = value.replace(/[^0-9]/g, "");
        e.target.value = num;
    }

    //숫자 세자리자동콤마
    const initNumberCommaHandler = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    //숫자만입력, 세자리자동콤마
    const onNumberCommaHandler = (e) => {
        let value = e.target.value;

        const nval = value.replaceAll(',', '').replace(/[^0-9]/g, "");
        const num = nval.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        e.target.value = num;
    }

    //콤마제거 및 타입변환
    const removeCommaHandler = (str) => {
        if (str == '' || str < 0) {
            return parseInt(0);
        }
        const num = parseInt(str.toString().replace(/,/g, ''));
        return num;
    }

    //영업시간리스트 API
    const [timeList, setTimeList] = useState();
    const [breakTimeOpt, setBreakTimeOpt] = useState([{value:1, name:"휴게시간없음"}]);
    const timeListAPI = async () => {
        axios.post(`${process.env.REACT_APP_BACK_HOST}timeMasterListForJson.do`)
            .catch(err => { console.log("Error >", err); })
            .then((response) => {
                const res = response.data;
                if (res.message == "SUCCESS") { setTimeList(res.data); setOpt(res.data);
                    if (breakTimeOpt.length <= 1) {
                        setBreakTimeOpt(breakTimeOpt.concat(res.data)) 
                    }
                }
            });
    }

    //공통코드리스트호출
    const [comnList2, setComnList2] = useState([]);
    const comnListAPI = async (str) => {
        setComnList([]);
        const DATA = { "COMN_CD": str };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}comnCdListForJson.do`, DATA)
                .catch(err => { console.log("Error >", err); reject(err); })
                .then((response) => {
                    const res = response.data;
                    if (res.message == "INVALID_DATA") { console.log("데이터가 존재하지 않습니다"); }
                    if (res.message == "SUCCESS") { setComnList(res.data); setComnList2(res.data); }
                    resolve(true);
                });
        })
    }

    //엑셀다운로드API
    const excelExportAPI = async (data, url, filename) => {
        const date = dayjs(new Date()).format('YYYYMMDDHH:mm:ss').toString();

        const HEADER = { responseType: "blob" };
        const response = await axios.post(url, data, HEADER).then((res) => {
            const url = window.URL.createObjectURL(
                new Blob([res.data], { type: res.headers["content-type"] })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename + `_${date}.xls`);
            document.body.appendChild(link);
            link.click();
        });
    }

    //예약점검카테고리
    const reservCategoryFilter = (category) => {
        return category == 1 ? "엔진오일" :
            category == 2 ? "타이어" :
                category == 3 ? "정기점검" :
                    category == 4 ? "기타" : null
    }

    //객체존재여부확인
    const isEmptyObj = (obj) => {
        if (obj.constructor === Object && Object.keys(obj).length === 0) {
            return true;
        }

        return false;
    }

    //화면리사이징리스너
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const windowSizes = useRef({ width: 0, height: 0 });
    const windowResizeHandler = () => {
        if (typeof window != 'undefined') {
            window.addEventListener("resize", windowResize);
            windowResize();
            return () => window.removeEventListener("resize", windowResize);
        } else {
            return () => window.removeEventListener("resize", () => {
                return null;
            });
        }
    }
    const windowResize = () => {
        windowSizes.current = { width: window.innerWidth, height: window.innerHeight };

        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    /* ===== Date ===== */
    //최초날짜셋팅(당일)
    const initDateHandler = (start, end) => {
        const date = new Date();
        const getDate = date.getDate();

        const endDate = date.setDate(getDate + (end));
        const startDate = date.setDate(getDate + (start));

        const StartDt = (dayjs(startDate).format('YYYY-MM-DD').toString()).replaceAll("-", ".");
        const endDt = (dayjs(endDate).format('YYYY-MM-DD').toString()).replaceAll("-", ".");

        setDatepicker({ isOpen: false, startDate: StartDt, endDate: endDt });
    }

    //날짜포맷변경(yyyyMMdd)
    const replaceDateHandler = (date) => {
        return date.replaceAll(".", "");
    }
    /* ===== Date ===== */

    //토큰 만료기간 설정 (refresh, access)
    const refreshTokenExpires = () => {
        const now = new Date();
        const expires = new Date(now.setFullYear(now.getFullYear() + 1));
        return expires;
    }
    const accessTokenExpires = () => {
        const now = new Date();
        const expires = new Date(now.setHours(now.getHours() + 1));
        return expires;
    }

    return {
        FontAwesomeIcon
        , compPreload, imgPreload//preload
        , onErrorImg//이미지처리
        , isStorage, getStorage, setStorage, updateStorage, removeStorage, clearStorage//secureStorage
        , timeList, breakTimeOpt, timeListAPI, comnList2, comnListAPI, excelExportAPI//공통API
        , onNumberHandler, initNumberCommaHandler, onNumberCommaHandler, removeCommaHandler//데이터타입변환
        , windowSizes, windowResizeHandler, windowSize//윈도우사이즈
        , initDateHandler, replaceDateHandler//날짜
        , isEmptyObj//Handler
        , reservCategoryFilter//예약카테고리
        , isOpen, setOpen//팝업
        , refreshTokenExpires, accessTokenExpires //토큰 만료기간 설정
    };
}

export default useComnHook;