import { useLocation } from 'react-router-dom';

import useRefreshAccessTokenHook from 'src/webapp/hooks/useRefreshAccessTokenHook';

const useAppHook = () => {
    const location = useLocation();

    //토큰유효성체크
    const { partnerTokenCheckAPI } = useRefreshAccessTokenHook();
    const partnerTokenCheckHandler = () => {
        if(location.pathname.includes("signup") || location.pathname.includes("login") || location.pathname.includes("find")) {
            return;
        }
        partnerTokenCheckAPI();
    }

    //화면리사이징핸들러
    const resizeHandler = () => {
        //화면리사이징
        setScreenSize();
        window.addEventListener('resize', setScreenSize);
        return () => window.removeEventListener('resize', setScreenSize);
    }
    // 100vh 화면높이감지
    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    return { partnerTokenCheckHandler, resizeHandler };
}

export default useAppHook;