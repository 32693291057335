import React, { useEffect, Suspense, lazy } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useFindHook from 'src/webapp/hooks/user/find.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _titleArea = lazyWithPreload(() => import('../_titleArea'));

export default function AuthPassWd() {

	//Hook
	const { compPreload } = useComnHook();//preload
	const { onValidatedHandler//비밀번호유효성검사
		, validated //유효성확인객체useState
		, onEnterHandler//엔터키작동핸들러
		, onSubmitHandler//버튼클릭작동핸들러
		, findPwRef//비밀번호찾기Ref
	} = useFindHook();

	//useEffect
	useEffect(() => {
		const comp = [_titleArea];
		compPreload(comp);//preload
	}, []);

	useEffect(() => {

	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}><_titleArea usePrev={true} title={`새 비밀번호 입력하기`} subtitle={`영문자/숫자/특수문자 조합, 8자 이상`} /></Suspense>
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						{/* 입력필드 */}
						<div className={styles.inputField}><label>비밀번호</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => onValidatedHandler(e)}
									name="passwd" type="password" inputMode="normal"
									ref={el => (findPwRef.current[0] = el)}
									placeholder="새 비밀번호 입력" />
								<label className="validateMsg">{validated.msg}</label>
							</div>
						</div>
						<div className={styles.inputField}><label>비밀번호 확인</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => onValidatedHandler(e)}
									onKeyDown={(e) => onEnterHandler(e, 3)}
									name="passwdChk" type="password" inputMode="normal"
									ref={el => (findPwRef.current[1] = el)}
									placeholder="새 비밀번호 확인" />
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => onSubmitHandler(3)}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">다음</button>
					</div>
				</div>
			</section>
		</>
	);
}