import React from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../_titleArea';
import _terms from './terms';

export default function Signup() {
	//Hook
	const { handleDataChange, //동의체크데이터
		pageOnLoadHandler, //버튼클릭
		checkItems, //체크활성화목록useState
		signupRef //회원가입Ref
	} = useSignupHook();

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					<_titleArea usePrev={true} title={`약관에 동의해주세요`} subtitle={`보다 쉬운 두바키 서비스 제공을 위해 꼭 필요합니다.`} />{/* 타이틀 */}
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.termListArea}>
							<_terms onData={handleDataChange} ref={signupRef} />{/* 동의하기 */}
						</div>
					</div>
					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={(e) => pageOnLoadHandler(0)}
							disabled={!checkItems}
							className="submitButton">동의하고 가입하기</button>
					</div>
				</div>
			</section>
		</>
	);
}