import React, { useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";

//Cookie
import { getCookie, removeCookie } from "src/webapp/config/cookie";
//Store
import usePartnerStore from "src/webapp/stores/partner.store";

const useAuthHook = () => {

    //navigator
    const navigator = useNavigate();
    
    //Store
    const { partner, setPartner, setState } = usePartnerStore();
    
    const isLock = useRef(false);//더블클릭방지

    /* ===== 로그아웃 ===== */
    //로그아웃API
    const onLogoutHandler = useCallback( async() => {
        if(!isLock.current) {
            isLock.current = true;
            try {
                const DATA = { "PARTNER_CD":partner.COMN_CD, "REFRESH_TOKEN":getCookie("NIDRP") };
                const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerTokenDeleteForPartner.do`, DATA);
                const res = response.data;
                if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다."); }
                if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                if (res.message == 'SUCCESS') {
                    removeAuthHandler();//회원정보삭제
                }
            } catch (error) { console.log("Error >" + error); }
            setTimeout(function() { isLock.current = false; }, 3000);
        }
    });
    /* ===== 로그아웃 ===== */

    /* ===== 회원탈퇴 ===== */
    const [ isOpen, setOpen ] = useState(false);
    //회원탈퇴팝업 on & off
    const onOpenHandler = () => { setOpen(true); }
    const handleDismissChange = (data) => { setOpen(data); }

    //회원탈퇴API
    const partnerWithdrawalAPI = useCallback( async() => {
        if(isLock.current) { return; }

        isLock.current = true;
        const DATA = { "PARTNER_CD":partner.COMN_CD, "STATE": 9 };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerMasterUpdateForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err);})
                .then((response) => {
                    const res = response.data;
                    if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다."); }
                    if(res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                    if(res.message == 'SUCCESS') { removeAuthHandler(); }//회원정보삭제
                    resolve(true);
                });
            setTimeout(function() { isLock.current = false; }, 3000);
        })
    });
    /* ===== 회원탈퇴 ===== */

    /* ===== 회원정보삭제 공통 ===== */
    //회원정보삭제
    const location = useLocation();
    const locate = location.pathname;
    const removeAuthHandler = () => {
        removeCookie("NIDAP");
        removeCookie("NIDRP");

        setState(false);
        setPartner({});
        if(locate == "/") {
            setTimeout(() => {
                navigator("/dashboard", { replace: true });
            }, 500)
        }else{
            setTimeout(() => {
                navigator("/", { replace: true });
            }, 500)
            
        }
    }
    /* ===== 회원정보삭제 공통 ===== */

    return { onLogoutHandler//로그아웃
        , isOpen, onOpenHandler, handleDismissChange, partnerWithdrawalAPI//회원탈퇴
    };
}

export default useAuthHook;