import React, { useEffect } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/component/navBar.module.scss'
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useAuthHook from 'src/webapp/hooks/auth/auth.hook';
import useNavBarHook from 'src/webapp/hooks/component/navBar.hook';
//Comp
import Img from 'src/webapp/views/component/picture/Img';

export default React.memo(function Nav(props) {
    //Store
    const { info } = usePartnerStore();
    //Hook
    const { navHandler//페이지이동처리
        , nav//메뉴리스트useState
    } = useNavBarHook();
    const { onLogoutHandler } = useAuthHook();//로그아웃핸들러
    //useEffect
    useEffect(() => {
        document.getElementById('scrollTarget').scrollIntoView({ behavior: 'smooth' });
    }, []);

    return (
        <>
        <div className={styles.navArea}>
            <div className={styles.header}>
                <div></div>
                <label>{info.PARTNER_NAME ?? '두바키 파트너'}님, 안녕하세요</label>
                <button onClick={(e) => onLogoutHandler()}><span>로그아웃</span><FontAwesomeIcon icon={['fal', 'arrow-right-from-bracket']} /></button>
            </div>
            <nav>
                <ul>
                    {nav.map((item, index) => {
                        return (
                            <li key={item.label} 
                                onClick={(e) => navHandler(item.nav)}
                                id={props.id == index ? "scrollTarget" : null}
                                className={`${props.id == index && styles.selected}`} >
                                <i><FontAwesomeIcon icon={['fal', `${item.icon}`]} /></i>
                                <span>{item.label}</span>
                            </li>
                        )
                    })}
                </ul>
            </nav>
            <div className={styles.footer}>
                <div className={[styles.imgArea, "imgArea", "rounded"].join(" ")}>
                    <Img src={info.IMG_SRC} alt={info.PARTNER_NAME} width={88} height={88} />
                </div>
                <div>
                    <label>+{info.DIFF ?? 0}일째 구독중</label>
                    <span>{info.LICENSE_DT ?? '0000-00-00'} 만료</span>
                </div>
                <small><FontAwesomeIcon icon={['fal', 'chevron-right']} /></small>
            </div>
        </div>
        </>
    );
});