import React, { useState, useCallback } from "react";
import axios from "axios";
//Store
import useNotifyStore from "src/webapp/stores/notify.store";
import usePartnerStore from "src/webapp/stores/partner.store";

const useNotifyHook = () => {
    //Stores
    const { partner } = usePartnerStore();
    //const { notifyList, setNotifyList } = useNotifyStore();

    //알림리스트API
    const [ page, setPage ] = useState(0);
    const [ notifyList, setNotifyList ] = useState([]);
    const notifyListAPI = useCallback(async(type) => {//type-0.첫로딩 or Socket, 1.스크롤
        if(page <= 0 || type == 0) { setNotifyList([]); }
        const DATA = { "PARTNER_CD": partner.COMN_CD, "SEQ_NO":type == 0 ? 0 : page };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerNotifyListForPartner.do`, DATA)
            .catch(err => {console.log("Error >", err); reject(err);})
            .then(response => {
                const res = response.data;
                if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if(res.message != "SUCCESS") { setPage(null); resolve([]); }
                if(res.message == "SUCCESS") {
                    setNotifyList(notifyList.concat(res.data));
                    setPage(res.data.length >= 20 ? res.data[res.data.length - 1].SEQ_NO : null);
                    resolve(res.data);
                }
            });
        });
    });

    //자세히보기
    const [ toggle, setToggle ] = useState(false);
    //const onHandleToggle = (id) => { setToggle({...toggle, [id]:!toggle[id]}); };
    const onHandleToggle = (id) => { setToggle({[id]:!toggle[id]}); };

    //알림상태반환
    const setTypeStr = (type) => {//type 유형-1.새요청, 2.예약변경, 3.예약취소, 4.기타
        switch(type) {
            case 1: return "새요청";
            case 2: return "예약변경";
            case 3: return "예약취소";
            case 4: return "기타";
        }
    }

    return { notifyList, notifyListAPI, setTypeStr, onHandleToggle, toggle, page };
}

export default useNotifyHook;