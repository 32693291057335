import React, { useEffect, Suspense, lazy } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//comp
import _emailForm from 'src/webapp/views/user/find/email/_emailForm';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';

const lazyWithPreload = importFunction => {
    const Component = lazy(importFunction);
    Component.preload = importFunction;

    return Component;
};
const _titleArea = lazyWithPreload(() => import('../_titleArea'));

export default function Verify() {

    //Hook
    const { compPreload//preload
        , getStorage//스토리지데이터가져오기 
        , isStorage
    } = useComnHook();
    const { verifyInputChangeHandler//인증번호입력
        , onhandleOnKeyPress//엔터이벤트
        , verifyCertCheckAPI //버튼클릭(인증번호확인)
        , signupCertReSendHandler//인증번호재전송
        , verifyMap//인증번호배열
        , validated//유효성확인객체useState
        , verifyRef//인증번호입력객체useRef
        , verify//인증번호입력객체useState
        , emailForm//이메일폼
        , verifyCnt//인증번호발송횟수useState
    } = useSignupHook();
    //useEffect
    useEffect(() => {
        const comp = [_titleArea];
        compPreload(comp);//preload
    }, []);

    useEffect(() => {
        // validatedVerifyHandler();//인증번호유효성
    }, [verify]);

    return (
        <>
            <section className={["container", styles.userContainer].join(" ")}>
                <div className={styles.wrapper}>
                    {/* 타이틀 */}
                    <Suspense fallback={null}>
                        <_titleArea
                            usePrev={true} title={`인증번호 입력`}
                            subtitle={`받으신 인증번호를 정확히 입력해주세요(` + verifyCnt + `/5)`} />
                    </Suspense>

                    {/* 입력필드 */}
                    <div className={["flickPanel", styles.verifyArea].join(" ")}>
                        {verifyMap.map((data, idx) => (
                            <div key={idx}>
                                <input onChange={(e) => verifyInputChangeHandler(e, idx)}
                                    onKeyDown={(e) => idx == 4 ? onhandleOnKeyPress(e, 5) : null}
                                    name={`verify` + idx} ref={(val) => verifyRef.current[idx] = val}
                                    type="text" inputMode="numeric"
                                    maxLength={1} placeholder={0} />
                            </div>
                        ))}
                    </div>

                    {/* 버튼 */}
                    <div className={["fixedPanel", styles.btnArea].join(" ")}>
                        <button onClick={() => isStorage('findId') ? verifyCertCheckAPI(2) : isStorage('findPw') ? verifyCertCheckAPI(3) : null}
                            disabled={validated.key == null ? true : validated.key ? true : false}
                            className="submitButton">확인</button>
                        <button onClick={(e) => signupCertReSendHandler(e)}
                            className="submitSecButton">다시 보내기</button>
                    </div>
                </div>
            </section>
            {/* 이메일 폼 */}
            {<div className="emailForm" ref={emailForm}><_emailForm /></div>}
        </>
    );
}