import React from 'react';

//style
import styles from 'src/asset/scss/style/schedule.module.scss'

export default function _status() {

    return (
        <>
            <div className={styles.statusArea}>
                <span className={styles.state1}><b /><label>새요청</label></span>
                <span className={styles.state2}><b /><label>예약완료</label></span>
                <span className={styles.state3}><b /><label>입고완료</label></span>
                <span className={styles.state4}><b /><label>정비완료</label></span>
                <span className={styles.state5}><b /><label>이력등록완료</label></span>
            </div>
        </>
    );
}