import React from 'react';
//style
import styles from 'src/asset/scss/style/dashboard.module.scss'

export default React.memo(function _sis(props) {
    //props
    const data = props.data;

    return (
        <>
        <ul className={styles.sisArea}>
            <li><label>새 요청</label><em>{data.CNT_1 ?? 0}건</em></li>
            <li><label>당일 총 예약</label><b>{data.CNT_2 ?? 0}건</b></li>
            <li><label>이력 미작성</label><b>{data.CNT_3 ?? 0}건</b></li>
        </ul>
        </>
    );
})