import React, { useCallback, useEffect, useState, Suspense, lazy, useRef } from 'react';
//observer
import { useInView } from 'react-intersection-observer';
//Store 
import useComnStore from 'src/webapp/stores/comn.store';
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useNotifyHook from 'src/webapp/hooks/notify/notify.hook';
//Comp
import Spinner from 'src/webapp/views/component/loading/Spinner';//최초로딩바
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const _notifyList = lazy(() => import('./notifyList'), { ssr: false });//알람리스트

export default function Notify() {
    //Stores
    const { socket } = useComnStore();
    const { isState } = usePartnerStore();
    //Hooks
    const { page//infinity페이지useState
        , notifyListAPI//알람리스트API 
    } = useNotifyHook();
    //Promise-Callback
    //데이터로딩
    const [isPending, setPending] = useState(true);
    const [notifyList, setNotifyList] = useState([]);//알람리스트
    // const NotifyListComp = () => { return !isPending ? <Suspense fallback={null}><_notifyList list={notifyList} /></Suspense> : null; }
    useEffect(() => {
        if (isState) {
            setPending(true)
            Promise.all([notifyListAPI(0)]).then((value) => {
                setNotifyList(value[0]);
                setPending(false);
            });
        }
    }, [isState, socket]);
    //infinity observer
    const [ref, inView] = useInView({ threshold: .5 });
    useEffect(() => {
        if (inView && (page > 0 || page != null)) {
            Promise.all([notifyListAPI(1)]).then((value) => {
                setNotifyList(notifyList.concat(value[0]));
            });
        }
    }, [inView]);

    return (
        <>
            <section className="fixedPanel container main">
                <NavBar id={1} />{/* 메뉴바 */}
                <div className="layer">
                    <div className="fixedPanel">
                        <TitleBar title={`알림`} />{/* 상단타이틀 */}
                    </div>
                    <div className="flickPanel">
                        {isPending ?
                            <div className="fullHeight"><Spinner isPending={true} /></div>
                            : <>
                                <div>
                                    {!isPending && notifyList.length > 0 ? <Suspense fallback={null}><_notifyList list={notifyList} /></Suspense> :
                                    <div className={"isEmpty"}>
                                    <FontAwesomeIcon icon={['fat', 'circle-exclamation']} /><span>조회내역이 없습니다</span>
                                </div> }</div>
                                {page == null ? null : page > 0 && notifyList.length > 0 ? <div ref={ref}><Spinner inView={true} /></div> : null}
                            </>}{/* 알림리스트 */}
                    </div>
                </div>
            </section>
        </>
    );
}