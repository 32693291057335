import React from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

export default function _step(props) {

    //Hook
    const { FontAwesomeIcon } = useComnHook();

	return (
		<>
        <div className={styles.stepArea}>
            <ul>
                <li className={props.step01 ? styles.selected : '' }>상호명</li>
                <li className={styles.arrow}><FontAwesomeIcon icon={['fat', 'chevron-right']} /></li>
                <li className={props.step02 ? styles.selected : '' }>주소</li>
                <li className={styles.arrow}><FontAwesomeIcon icon={['fat', 'chevron-right']} /></li>
                <li className={props.step03 ? styles.selected : '' }>영업시간</li>
                <li className={styles.arrow}><FontAwesomeIcon icon={['fat', 'chevron-right']} /></li>
                <li className={props.step04 ? styles.selected : '' }>정비인원</li>
                <li className={styles.arrow}><FontAwesomeIcon icon={['fat', 'chevron-right']} /></li>
                <li className={props.step05 ? styles.selected : '' }>정산계좌</li>
                <li className={styles.arrow}><FontAwesomeIcon icon={['fat', 'chevron-right']} /></li>
                <li className={props.step06 ? styles.selected : '' }>이미지 등록</li>
            </ul>
            <div><span>{props.idx}/6</span></div>
        </div>
		</>
	);
  }