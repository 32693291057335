import { create } from "zustand";

const useMembersStore = create((set) => ({
    memberList: [],//예약회원리스트
    memberDetailList: [],//예약회원리스트
    detailOpen: {},//상세페이지객체정보

    setMemberList: (memberList) => set((state) => ({ ...state, memberList })),
    setMemberDetailList: (memberDetailList) => set((state) => ({ ...state, memberDetailList })),
    setDetailOpen: (detailOpen) => set((state) => ({ ...state, detailOpen })),
}));

export default useMembersStore;