import { create } from "zustand";

const useScheduleStore = create((set) => ({
    dayTimeList: [],//일자별스케줄타임리스트
    dayList: [],//일자별스케줄리스트
    afterDaysList: [],//오늘이후상태리스트
    profile: {},//새예약요청 데이터객체
    //scheduleData: { SEQ_NO:0 },//새예약요청데이터객체
    mechanics : 0,//스케쥴러-정비사인원수

    setDayTimeList: (dayTimeList) => set((state) => ({...state, dayTimeList})),
    setDayList: (dayList) => set((state) => ({...state, dayList})),
    setAfterDaysList: (afterDaysList) => set((state) => ({...state, afterDaysList})),
    setProfile: (profile) => set((state) => ({...state, profile})),
    //setScheduleData: (scheduleData) => set((state) => ({...state, scheduleData})),
    setMechanics: (mechanics) => set((state) => ({...state, mechanics})),
}));

export default useScheduleStore;