import React, { useEffect, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';

//image
import successIcon_Img from 'src/asset/images/icon/common-success.webp';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _titleArea = lazyWithPreload(() => import('../_titleArea'));

export default function Signup() {

	//navigator
	const navigator = useNavigate();

	//Hook
	const { compPreload//preload
		, imgPreload//preload
	} = useComnHook();

	useEffect(() => {
		const images = [successIcon_Img];
		imgPreload(images);//preload

		const comp = [_titleArea];
		compPreload(comp);//preload
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}>
						<_titleArea usePrev={true} title={`회원가입 신청이 완료되었습니다`} subtitle={`빠른 시일 내에 확인 후 연락드리겠습니다.`} />
					</Suspense>

					<div className={"flickPanel"}>
						<div className={styles.imgArea}><img src={successIcon_Img} /></div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => navigator("/")}
							className="submitButton">확인</button>
					</div>
				</div>
			</section>
		</>
	);
}