import React, { useState } from 'react';
import axios from "axios";
//Store
import usePartnerStore from "src/webapp/stores/partner.store";
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import { useCallback } from "react";

const useCalcHook = () => {
    //Store
    const { partner } = usePartnerStore();
    //Hook
    const { replaceDateHandler, excelExportAPI } = useComnHook();

    //정산리스트API
    const [ calcList, setCalcList ] = useState([]);
    const calcListAPI = useCallback(async (date) => {
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);
        const DATA = { "PARTNER_CD":partner.COMN_CD, "START_DATE":startDate, "END_DATE":endDate };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerCalcListForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err)})
                .then((response) => {
                    const res = response.data;
                    if(res.message == "INVALID_REUEST") { console.log("잘못된 요청입니다"); }
                    if(res.message != "SUCCESS") { setCalcList([]); resolve([]); }
                    if(res.message == "SUCCESS") { setCalcList(res.data); resolve(res.data);  }
                })
        })
    });

    //엑셀다운로드
    const excelExportHandler = async(date) => {
        if(date.startDate != '' && date.endDate != '') {}
        const startDate = replaceDateHandler(date.startDate);
        const endDate = replaceDateHandler(date.endDate);

        const data = { "PARTNER_CD":partner.COMN_CD, "START_DATE":startDate, "END_DATE":endDate };
        const url = `${process.env.REACT_APP_BACK_HOST}partnerCalcListExcel.do`;
        const filename = `[두바키 파트너스]${startDate} - ${endDate}정산 관리`;

        excelExportAPI(data, url, filename);//엑셀다운로드API
    };

    //아이템 총 가격
    const setTotalPrice = (list) => {
        let sum = 0;
        for (let i = 0; list.length > i; i++) {
            let price = parseInt(list[i].CALC_PRICE.replaceAll(',', '').replace(/[^0-9]/g, ""));
            sum += price;
        }
        return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return { calcList, calcListAPI//정산결과
        , setTotalPrice//정산합계금액
        , excelExportHandler//엑셀다운로드
    };
}

export default useCalcHook;