import React from 'react';
//style
import styles from 'src/asset/scss/component/loader.module.scss';

const Spinner = (props) => {
    return (
        <>
        {props.isPending ? 
        <div className={styles.pending}>Loading...</div>
        :
        <div className={props.inView ? styles.infiniteScroll : null}>
            <div className={styles.loaderArea}>
                <div className={styles.loader}>
                    <div className={[styles.dot, styles.dot1].join(" ")}></div>
                    <div className={[styles.dot, styles.dot2].join(" ")}></div>
                    <div className={[styles.dot, styles.dot3].join(" ")}></div>
                    <div className={[styles.dot, styles.dot4].join(" ")}></div>
                </div>
                <label>{props.label}</label>
            </div>
        </div> }
        </>
    )
}
export default React.memo(Spinner);