import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';

const useMyPageNoticeHook = () => {
    //Store
    const { partner } = usePartnerStore();
    //State & Ref
    const [ notice, setNotice ] = useState({reg:false, update:false});
    const isLock = useRef(false);//더블클릭방지
    const noticeRef = useRef([]);//공지사항입력ref

    /* ===== 공지사항리스트 ===== */
    //공지사항리스트API
    const [ boardList, setBoardList ] = useState([]);
    const boardListAPI = useCallback(async() => {
        const DATA = { "PARTNER_CD":partner.COMN_CD };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerBoardListForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err);})
                .then((response) => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "SUCCESS") { setBoardList(res.data); resolve(res.data); }
                    resolve(res);
                });
        })
    });
    /* ===== 공지사항리스트 ===== */
    /* ===== 공지사항 등록&수정 ===== */
    //팝업 on & off
    const onOpenHandler = (name, value) => { setNotice({ ...notice, [name]: value }); }
    const handleDismissChange = (data) => { setNotice({reg:false, update:false}); }

    //입력검증핸들러
    const [ btnDisabled, setBtnDisabled ] = useState(true);
    const inputValidateHandler = () => {
        let bval = true;
        for(let i = 0; i < noticeRef.current.length; i++) {
            if(noticeRef.current[i].value.length > 0) { bval = false; }
            else { bval = true; break; }
        }
        setBtnDisabled(bval);
    }

    //엔터클릭이벤트
    const onhandleOnKeyPress = (e, idx, seq) => {
        if(e.key == "Enter" && !e.shiftKey) {
            switch(idx) {
                case 1: noticeRegAPI(); break;
                case 2: noticeUpdateAPI(idx, seq); break;
            }
        }
    }
    /* ===== 공지사항 등록&수정 ===== */
    /* ===== 공지사항등록 ===== */
    //공지사항등록API
    const noticeRegAPI = useCallback( async() => {
        if(isLock.current) { return; }

        isLock.current = true;
        const DATA = { "PARTNER_CD":partner.COMN_CD, "SUBJECT":noticeRef.current[0].value, "CONTENT":noticeRef.current[1].value };
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerBoardRegForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); })
                .then((response) => {
                    resolve(true);

                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                    if (res.message == "SUCCESS") { }
                });
            setTimeout(function() { isLock.current = false; }, 3000);
        });
    });
    /* ===== 공지사항등록 ===== */
    /* ===== 공지사항 수정 ===== */
    //공지사항수정 데이터셋
    const updateRef = useRef({});
    const setDataIdxHandler = (item) => { updateRef.current = item; }

    //공지사항수정 및 삭제 API - type- 1.삭제, 2.수정
    const noticeUpdateAPI = useCallback( async(type, seq) => {
        if(isLock.current) { return; }

        isLock.current = true;
        const DATA = { "SEQ_NO":seq, "PARTNER_CD":partner.COMN_CD
            , "SUBJECT":noticeRef.current[0].value, "CONTENT":noticeRef.current[1].value, "DEL_YN":type == 1 ? "Y" : "N"};
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BACK_HOST}partnerBoardUpdateForPartner.do`, DATA)
                .catch(err => {console.log("Error >", err); reject(err); })
                .then((response) => {
                    const res = response.data;
                    if (res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                    if (res.message == "FAILED_DB_PROCESSING") { console.log("잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다"); }
                    if (res.message == "SUCCESS") { }

                    resolve(true);
                });
            setTimeout(function() { isLock.current = false; }, 3000);
        });
    });
    /* ===== 공지사항 수정 ===== */

    return { boardList, boardListAPI//공지사항리스트
        , onOpenHandler, handleDismissChange//공지사항등록&수정팝업
        , notice, noticeRef, btnDisabled, onhandleOnKeyPress, inputValidateHandler//입력및엔터이벤트
        , noticeRegAPI//공지사항등록
        , updateRef, setDataIdxHandler, noticeUpdateAPI//공지사항수정
    };
}

export default useMyPageNoticeHook;