import React, { useEffect } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//notionRender
import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
//Hooks
import useSignupHook from 'src/webapp/hooks/user/signup.hook';

export default React.forwardRef((props, signupRef) => {    
    const { checkboxCheckedAll//약관전체동의
        , checkboxSingleChecked//약관개별동의
        , terms//이용약관리스트
        , checkItems//약관동의체크useState
        , onHandleToggle//자세히보기
        , toggle//자세히보기useState
    } = useSignupHook();
    //useEffect
    useEffect(() => {
        props.onData(checkItems);//데이터상위컴포넌트로 전달
    } ,[checkItems]);

	return (
		<>
        {/* 전체동의 */}
        <div className={styles.ChkAll}>
            <input onChange={(e) => {checkboxCheckedAll(e.target.checked, signupRef);}}
            ref={el => (signupRef.current[2] = el)}
                type='checkbox' name='select-all'
                />
            <label>전체 약관에 동의합니다.</label>
        </div>

        {/* 개별동의 */}
        {terms?.map((terms, key) => (
            <div className={styles.checkBoxArea} key={key}>
                <div className={styles.checkbox}>
                    <input type='checkbox' name={`select-${terms.id}`}
                        onChange={(e) => {checkboxSingleChecked(e.target.checked, signupRef);}}
                        ref={el => (signupRef.current[key] = el)}
                        />
                    <label>{terms.title}</label>
                    <small onClick={(e) => onHandleToggle(terms.id)}>{toggle[terms.id] ? '닫기' : '보기'}</small>
                </div>
                <pre className={toggle[terms.id] ? "show" : "hide"}><NotionRenderer blockMap={terms.content} fullPage={true}/></pre>
            </div>
        ))}
        </>
	);
})