import React, { useEffect, Suspense, lazy, useCallback, useState } from 'react';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/dashboard.module.scss'
//Store
import useComnStore from 'src/webapp/stores/comn.store';
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useDatepickerHook from 'src/webapp/hooks/component/datepicker.hook';
import useDashBoardHook from 'src/webapp/hooks/dashboard/dashboard.hook';
import useVehicleRegHook from 'src/webapp/hooks/vehicle/vehicleReg.hook';//차량등록
//Comp
import Spinner from 'src/webapp/views/component/loading/Spinner';//로딩바
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import _sis from './comp/sis';
//대시보드Comp
const _lineChart	 = lazy(() => import('./comp/lineChart'), { ssr: false });//라인차트
const _donutChart	 = lazy(() => import('./comp/donutChart'), { ssr: false });//도넛차트
const _reservList	 = lazy(() => import('./comp/reservList'), { ssr: false });//당일및익일예약내역
//차량등록Comp
const _bsVehicleRegInit     = lazy(() => import('src/webapp/views/vehicle/BsVehicleRegInit'), { ssr: false });//라인차트
const _bsVehicleRegBrand    = lazy(() => import('src/webapp/views/vehicle/BsVehicleRegBrand'), { ssr: false });//라인차트
const _bsVehicleRegModel    = lazy(() => import('src/webapp/views/vehicle/BsVehicleRegModel'), { ssr: false });//라인차트
const _bsVehicleRegSuccess  = lazy(() => import('src/webapp/views/vehicle/BsVehicleRegSuccess'), { ssr: false });//라인차트
//bottomsheet
const _bsRangeDatepicker  = lazy(() => import('src/webapp/views/component/BsRangeDatepicker'), { ssr: false });//라인차트

export default function Dashboard() {
    //Stores
    const { socket } = useComnStore();
    const { isState } = usePartnerStore();
    //Hooks
    const { initDateHandler
        , rangeIsOpen//날짜선택팝업useState
        , onRangeOpenHandler//날짜선택팝업on
        , handleRangePickerChange//날짜선택팝업off
        , rangeDate//선택된날짜useState 
    } = useDatepickerHook();//날짜팝업
    const { vehicleCntAPI//취급차량등록확인
        , dashboardSisAPI//당일통계API
        , lineChartAPI//라인차트API
        , donutChartAPI//도넛차트API
        , reservDateListAPI//당일 및 익일예약내역API
    } = useDashBoardHook();
    const { isOpen//팝업오픈여부
        , changeOpenHandler//팝업화면전환핸들러
        , onDismissHandler//팝업off핸들러
        , brandItem//선택브랜드useState
        , handleBrandChange//모델선택핸들러
    } = useVehicleRegHook();
    //로딩처리상태
    const [ isPending, setPending ] = useState(true);
    useEffect(() => {
        initDateHandler(-7, 0);//날짜초기셋팅(7일전~당일)
    }, []);
    //차량등록여부
    useEffect(() => {
        if(isState) {
            Promise.all([ vehicleCntAPI() ]).then((value) => {
                if(value[0] <= 0) { changeOpenHandler("init") }
			});
        }
    }, [isState])
    //대시보드데이터
    const [ calcList, setCalcList ] = useState({});
    const [ todayReservList, setTodayReservList ] = useState([]);
    // const TodayReservListComp = () => { return !isPending ? <Suspense fallback={null}><_reservList todayReservList={todayReservList} title={`당일`} type={1} /></Suspense> : null; }
    const [ tomorrowReservList, setTomorrowReservList ] = useState([]);
    // const TomorrowReservListComp = () => { return !isPending ? <_reservList tomorrowReservList={tomorrowReservList} title={`익일`} type={2} /> : null; }
    useEffect(() => {
        if (isState) {
            Promise.all([ dashboardSisAPI(), reservDateListAPI(1), reservDateListAPI(2), ]).then((value) => {
                setCalcList(value[0]);
                setTodayReservList(value[1]);
                setTomorrowReservList(value[2]);
			});
        }
    }, [isState, socket])
    //차트데이터
    const [ lineChart, setLineChart ] = useState([]);
    const LineChartComp = () => { return !isPending ? <Suspense fallback={null}><_lineChart data={lineChart} /></Suspense> : null; }
    const [ donutChart, setDonutChart ] = useState([]);
    const DonutChartComp = () => { return !isPending ? <Suspense fallback={null}><_donutChart data={donutChart} /></Suspense> : null; }
    useEffect(() => {
        setPending(true);
        if (isState && rangeDate != false) {
            Promise.all([ lineChartAPI(rangeDate), donutChartAPI(rangeDate) ]).then((value) => {
                setLineChart(value[0]);
                setDonutChart(value[1]);
				setPending(false);
			});
        }
    }, [isState, socket, rangeDate])
    //function
    const handleSuccess = () => {}

	return (
		<>
        <section className="fixedPanel container main">
            <NavBar id={0} />{/* 메뉴바 */}
			<div className="layer">
                <div className="fixedPanel">
                    <TitleBar title={`대시보드`} />{/* 타이틀 */}
                    <div className={styles.sisDesktop}><_sis data={calcList} /></div>{/* 통계자료 */}
                    <div className="searchArea">{/* 검색창 */}
                        <div className="dateArea">
                            <div className="datepicker">{(rangeDate.startDate ?? '') + ` - ` + (rangeDate.endDate ?? '')}</div>
                            <button onClick={(e) => onRangeOpenHandler()}
                                className="btnCalendar"><FontAwesomeIcon icon={['fal', 'calendar-lines']} /></button>
                        </div>
                    </div>
                </div>
                <div className="flickPanel">
                    {isPending ? 
                        <div className="fullHeight"><Spinner isPending={true} /></div>
                    : <>
                        <div className={styles.sisMobile}>
                            <_sis data={calcList} />{/* 당일통계 */}
                        </div>
                        <div className={styles.dashboardArea}>
                            <div className={styles.gridArea}>
                                <LineChartComp />{/* 라인차트 */}{!isPending ? <Suspense fallback={null}><_reservList todayReservList={todayReservList} title={`당일`} type={1} /></Suspense> : null}{/* 당일 예약내역 */}
                            </div>
                            <div className={styles.gridArea}>
                                <DonutChartComp />{/* 도넛차트 */}{!isPending ? <_reservList tomorrowReservList={tomorrowReservList} title={`익일`} type={2} /> : null}{/* 익일 예약내역 */}
                            </div>
                        </div>
                        </>
                    }
                </div>
            </div>
        </section>

        {/* bottomSheet-datepicker */}
        {rangeIsOpen ? <Suspense fallback={null}><_bsRangeDatepicker isOpen={rangeIsOpen} onDismiss={handleRangePickerChange} /></Suspense> : null}
        {/* 차량등록 bottomSheet */}
        {isOpen.init ? <Suspense fallback={null}><_bsVehicleRegInit isOpen={isOpen.init} onDismiss={onDismissHandler} /></Suspense> : null}
        {isOpen.brand ? <Suspense fallback={null}><_bsVehicleRegBrand isOpen={isOpen.brand} onBrand={handleBrandChange} onDismiss={onDismissHandler} /></Suspense> : null}
        {isOpen.model ? <Suspense fallback={null}><_bsVehicleRegModel isOpen={isOpen.model} brand={brandItem} onSuccess={handleSuccess} onDismiss={onDismissHandler} /></Suspense> : null}
        {isOpen.success ? <Suspense fallback={null}><_bsVehicleRegSuccess isOpen={isOpen.success} onDismiss={onDismissHandler} /></Suspense> : null}
		</>
	);
  }