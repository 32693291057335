import React from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss'
//Comp
import StatusBar from '../component/StatusBar';

export default function _titleArea(props) {

	return (
		<>
		<div className="fixedPanel">
			<StatusBar usePrev={props.usePrev} />
			<div className={styles.Title}>{props.title}</div>
			{props.subtitle ? 
				<div className={[styles.subtitle, props.isMobile ? styles.isMobile : ''].join(" ")}>{props.subtitle}</div>
			: null}
		</div>
		</>
	);
  }