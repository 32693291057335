import React from 'react';
//image
import onError_Img from 'src/asset/images/no-image.webp';
//hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const Img = React.memo((props) => {
    //props
    const src = props.src === 'undefined' || props.src === undefined || props.src === '/no-image.webp' ? true : false;
    const params = '?auto=format,enhance&foramt=webp&q=60&w=' + props.width + '&h=' + props.height;
    const imgSrc = src ? onError_Img : `${process.env.REACT_APP_IMAGE_URL + props.src + params}`;
    const alt = props.alt;
    //hook
    const { onErrorImg } = useComnHook();//대체이미지
    const a = () => {
        if (props.searchWord.length > 0) {
            if (props.modelList.filter((item) => props.brandCd == item.BRAND_CD && item.MODEL_KOR_NAME.includes(props.searchWord)).length 
            == props.checkedList.filter((item) => props.brandCd == item.BRAND_CD && item.MODEL_KOR_NAME.includes(props.searchWord)).length) {
                return false;
            }else{
                return true;
            }
        }else{
            if (props.modelList.filter((item) => props.brandCd == item.BRAND_CD).length 
            == props.checkedList.filter((item) => props.brandCd == item.BRAND_CD).length){
                return false;
            }else{
                return true;
            }
        }
    }

    return (
        <>
        <picture onClick={(e) => props.brandCd ? props.logoClickHandler(a(), props.searchWord, props.brandCd) : null}>
            <source srcSet={imgSrc} type="image/webp"></source>
            <img className="thumbnail" 
                sizes={`(min-width:1024ppx) ${props.width/2}px, (min-width:767px) ${props.width/2}px, ${props.width/2}px`}
                src={imgSrc} onError={onErrorImg}
                srcSet={`${imgSrc} 1x, ${imgSrc} 2x, ${imgSrc} 1x`} alt={alt} />
        </picture>
        </>
    )
})
export default React.memo(Img);