
import React, { useState } from 'react';

const useAttachFileHook = () => {
    const [maxCnt, setMaxCnt] = useState(0);//업로드갯수
    const [files, setFiles] = useState([]);//파일배열

    //업로드갯수설정
    const setMaxCountHandler = (val) => { setMaxCnt(val); }
    //파일선택
    const chooseFileUploadHandler = (e, type) => { //type 1. 사업자등록증 3.외관업체사진
        if( type == 1) {
            document.getElementById("hiddenFile").click();
        }
        if( type == 3) {
        document.getElementById("hiddenFiles").click();
        }
    }
    //파일저장
    const addFileHandler = (e) => {
        const IMAGE_MAX_SIZE = 5 * 1024 * 1024;
        const IMAGE_MIN_SIZE = 5 * 1024;
        let temp = [];
        const photoToAdd = e.target.files;
        for (let i = 0; i < photoToAdd.length; i++) {
            if (IMAGE_MAX_SIZE < photoToAdd[i].size || photoToAdd[i].size < IMAGE_MIN_SIZE) {
                alert("5KB이상 5MB이하 파일만 업로드할수있습니다.");
                return;
            }
            temp.push({ id: photoToAdd[i].name, file: photoToAdd[i], url: URL.createObjectURL(photoToAdd[i]) })
        };
        //최대업로드갯수 이상은 삭제
        if (temp.length > maxCnt) { temp = temp.slice(0, maxCnt); }
        setFiles(temp.concat(files));
    };
    //파일삭제
    const deleteFileHandler = (id) => { setFiles(files.filter(photo => photo.url != id)) };

    return {
        setMaxCountHandler, chooseFileUploadHandler, addFileHandler, deleteFileHandler, files
    }
}

export default useAttachFileHook;