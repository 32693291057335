import React, { useEffect, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';

//image
import failIcon_Img from 'src/asset/images/icon/common-fail.png';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const lazyWithPreload = importFunction => {
	const Component = lazy(importFunction);
	Component.preload = importFunction;

	return Component;
};
const _titleArea = lazyWithPreload(() => import('../_titleArea'));

export default function Signup() {

	//navigator
	const navigator = useNavigate();

	//Hook
	const { compPreload//preload
		, imgPreload//preload
	} = useComnHook();

	//useEffect
	useEffect(() => {
		const images = [failIcon_Img];
		imgPreload(images);//preload

		const comp = [_titleArea];
		compPreload(comp);//preload
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					{/* 타이틀 */}
					<Suspense fallback={null}>
						<_titleArea usePrev={true} title={`회원가입에 실패했습니다`} subtitle={`두바키 서버에 연결할 수 없습니다.\n해당 오류가 반복 되면 네트워크 관리자에게 문의 하세요.`} />
					</Suspense>

					<div className={"flickPanel"}>
						<div className={styles.imgArea}><img src={failIcon_Img} /></div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => navigator("/")}
							className="submitButton">확인</button>
					</div>
				</div>
			</section>
		</>
	);
}