import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

//DateFormat
import dayjs from 'dayjs';
//Store
import useComnStore from 'src/webapp/stores/comn.store';

const useRangeDatePickerHook = () => {

    /* 시작일 ~ 종료일 */
    //팝업 on & off
    const [ rangeIsOpen, setRangeOpen ]  = useState(false);
    const [ rangeDate, setRangeDate ]  = useState(false);
    const onRangeOpenHandler = () => { setRangeOpen(true); }
    const handleRangePickerChange = (data) => { 
        setRangeOpen(false); 
        if(data != null) {
            setRangeDate(data); 
        }
    }

    /* ~ 종료일 */
    //팝업 on & off
    const [ dateIsOpen, setDateOpen ]  = useState(false);
    const [ endDate, setEndDate ]  = useState(false);
    const onDateOpenHandler = () => { setDateOpen(true); }
    const handleDatepickerChange = (data) => { 
        setDateOpen(false); 
        if(data != null) {
            setEndDate(data); 
        }
    }

    const [ date, setDate ] = useState({ startDt:new Date(), endDt:new Date() });
    //날짜선택완료
    const onSelectedHandler = (date) => {
        const today = new Date();
        const day = today.getDate();
        //검색시작일
        let startDt = date.startDt ?? new Date(today.setDate(day - 7));
        startDt = (dayjs(startDt).format('YYYY-MM-DD')).toString().replaceAll("-", ".");
        //검색종료일
        let endDt = date.endDt ?? new Date;
        endDt = (dayjs(endDt).format('YYYY-MM-DD')).toString().replaceAll("-", ".");

        return new Promise((resolve, reject) => {
            setTimeout(function() { resolve({ startDate:startDt, endDate:endDt }); }, 300);
        });
    }

    //최초날짜셋팅
    const initDateHandler = (start, end) => {
        const date = new Date();
        const getDate = date.getDate();
        
        let endDt = date;
        if(end.length > 8) {
            endDt = end.replaceAll('-', '.');
        } else {
            endDt = date.setDate(getDate + (end));
            endDt = (dayjs(endDt).format('YYYY-MM-DD').toString()).replaceAll("-", ".")
        }
        let startDt = date;
        if(start.length > 8) {
            startDt = end.replaceAll('-', '.');
        } else {
            startDt = date.setDate(getDate + (start));
            startDt = (dayjs(startDt).format('YYYY-MM-DD').toString()).replaceAll("-", ".")
        }
        setRangeDate({ startDate:startDt, endDate:endDt });
        setEndDate({ startDate:startDt, endDate:endDt });
        //setDatepicker({ isOpen: false, startDate: StartDt, endDate: endDt });
    }

    const { datepicker, setDatepicker, setMovePage } = useComnStore();

    //날짜-start:시작일, end:종료일
    const onDatepickerHandler = (name, value) => {
        setDatepicker({...datepicker, [name]: value});
    }

    //캘린더날짜선택
    const onChangesHandler = (dates) => {
        const [start, end] = dates;
        setDate({ startDt:start, endDt:end });
    };
    const onChangeHandler = (date) => {
        setDate({ startDt:'', endDt:date });
    };

    //날짜선택완료
    const onSelectHandler = () => {
        let startDt = '';
        let endDt = '';
        if(date.startDt == '') {
            startDt = (dayjs(new Date).format('YYYY-MM-DD').toString()).replaceAll("-", ".");
        } else {
            startDt = (dayjs(date.startDt).format('YYYY-MM-DD').toString()).replaceAll("-", ".")
        }

        if(date.endDt == '') {
            endDt = (dayjs(new Date).format('YYYY-MM-DD').toString()).replaceAll("-", ".");
        } else {
            endDt = (dayjs(date.endDt).format('YYYY-MM-DD').toString()).replaceAll("-", ".")
        }
        if (date.endDt == null) {
            endDt = startDt;
        }

        setDatepicker({ isOpen:false, startDate:startDt, endDate:endDt });
    };

    //날짜선택및페이지이동
    const navigator = useNavigate();
    const onLoadHandler = (date, linked) => {
        setDatepicker({...datepicker, endDate: date});
        setMovePage(true);
        if(linked) { navigator("/schedule", {replace: false}); }
    }

    //닫기
    const onDismissHandler = () => {
        setDatepicker({ isOpen:false, startDate:datepicker.startDate, endDate:datepicker.endDate });
    };

    return { rangeIsOpen, onRangeOpenHandler, handleRangePickerChange//rangedatepicker
        , onSelectedHandler//datepicker날짜선택
        , rangeDate, endDate//선택날짜useState
        , initDateHandler//rangedatepicker최초날짜셋팅
        , dateIsOpen, onDateOpenHandler, handleDatepickerChange//datepicker
        
        , date, onDatepickerHandler, onChangesHandler, onChangeHandler, onSelectHandler, onDismissHandler, onLoadHandler };
}

export default useRangeDatePickerHook;