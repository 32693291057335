import React, { useEffect, Suspense, lazy } from 'react';

//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//Store
import useComnStore from 'src/webapp/stores/comn.store';
//Hook
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';
import __step from './step';

export default function Step05() {
	//Store
	const { opt } = useComnStore();
	//Hook
	const { checkedStorage //스토리지유무확인
		, onhandleOnKeyPress//엔터이벤트
		, bankListAPI//은행리스트API
		, changeBankHandler//은행선택
		, validatedAccountNoHandler//계좌번호유효성체크
		, pageOnLoadHandler //버튼클릭
		, validated //유효성확인객체useState
		, signupRef//회원가입Ref
	} = useSignupHook();
	//useEffect
	useEffect(() => {
		checkedStorage();//쿠키유무확인
		bankListAPI();//은행리스트API
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					<_titleArea usePrev={true} title={`업체 등록하기`} subtitle={`정산받으실 계좌번호를 입력해주세요`} isMobile={true} />{/* 타이틀 */}
					<__step step05={true} idx={5} />{/* 스텝 */}

					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.inputField}><label>은행/증권사</label>
							<div className="select">
								<SelectSearch onChange={(e, idx) => {changeBankHandler();(signupRef.current[0].value = e)}}
								ref={el => (signupRef.current[0] = el)}
									options={opt} value={opt.value}
									placeholder='은행/증권사' />
							</div>
						</div>
						<div className={styles.inputField}><label>계좌번호</label>
							<div className={validated.key == null ? null : validated.key ? "invalidate" : "validate"}>
								<input onChange={(e) => validatedAccountNoHandler(e)}
									onKeyDown={(e) => onhandleOnKeyPress(e, 10)}
									type="text" inputMode="numeric" name="accountNo"
									ref={el => (signupRef.current[1] = el)}
									placeholder="-을 제외한 숫자만 입력해주세요" />
								<label className="validateMsg">{validated.msg}</label>
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => pageOnLoadHandler(10)}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">다음</button>
					</div>
				</div>
			</section>
		</>
	);
}