import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
//Cookie
import { getCookie, removeCookie, setCookie } from 'src/webapp/config/cookie';
//Store
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';

const useRefreshAccessTokenHook = () => {
    //navigator
    const navigate = useNavigate();
    //토큰체크
    //Store
    const { partner, setPartner, setState, setInfo } = usePartnerStore();
    //Hook
    const { refreshTokenExpires, accessTokenExpires } = useComnHook();//토큰 만료설정

    //토큰 체크
    const partnerTokenCheckAPI = useCallback(async () => {
        const NIDAP = getCookie("NIDAP");
        const NIDRP = getCookie("NIDRP");
        const DATA = { "REFRESH_TOKEN":NIDRP, "ACCESS_TOKEN":NIDAP ?? null };

        return new Promise((resolve, reject) => { 
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnerAuthCheckForPartner.do`, DATA)
        .then(response => {
            const res = response.data
            if(res.message == "AUTH_REQUEST_FAILED") {
                removeCookie('NIDAP');
                removeCookie('NIDRP');
                resolve(false);
            }
            if(res.message == "AUTH_REQUEST_CREATE_SUCCESS") {
                const refreshExpires = refreshTokenExpires();
                const accessExpires = accessTokenExpires();

                setCookie("NIDRP", res.data.REFRESH_TOKEN, { expires: refreshExpires });
                setCookie("NIDAP", res.data.ACCESS_TOKEN, { expires: accessExpires });
                resolve(true);
            }
            if(res.message == "AUTH_REQUEST_SUCCESS" || res.message == "AUTH_REQUEST_CREATE_SUCCESS") {
                setPartner(res.data);
                setState(true);

                partnersInfoAPI(res.data.COMN_CD);
                resolve(true);
            }
        })
        .catch(err => {  console.log("Error >", err); });
    })
    });

    //파트너정보API
    const partnersInfoAPI = useCallback( async(partnerCd) => {
        if(Object.keys(partner).length < 0) { return; }

        const DATA = { "PARTNER_CD": partnerCd ?? partner.COMN_CD };
        axios.post(`${process.env.REACT_APP_BACK_HOST}partnersMasterInfoForPartner.do`, DATA)
            .catch(err => {  console.log("Error >", err); })
            .then(response => {
                const res = response.data;
                if(res.message == "INVALID_REQUEST") { console.log("잘못된 요청입니다"); }
                if(res.message == "INVALID_DATA") { console.log("데이터가 존재하지 않습니다"); }
                if(res.message == "SUCCESS") { setInfo(res.data); }
            });  
    });

    return { partnerTokenCheckAPI, partnersInfoAPI };
}

export default useRefreshAccessTokenHook;