import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";

//약관내용
import { termsTitle1, termsTitle2 } from 'src/webapp/constants/comn';
import temrsContent1 from 'src/json/terms/3388afb637cd4ad793f4a7de3eb1c506.json';
import temrsContent2 from 'src/json/terms/6923f04b1cd04a67987a6c4712dd49da.json';
//Hook
import useComnHook from "src/webapp/hooks/comn/comn.hook";
//Store
import useComnStore from 'src/webapp/stores/comn.store';

const useUserHook = () => {

    const item = "signup";
    const signupRef = useRef([]);
    //Stores
    const { setOpt } = useComnStore();
    //Hooks
    const { isStorage, getStorage, setStorage, updateStorage, clearStorage, removeStorage } = useComnHook();
    const [validated, setValidated] = useState({ key: null, msg: '' });

    const navigator = useNavigate();
    const location = useLocation();
    /* ===== 공통 ===== */
    //스토리지존재유무확인
    const checkedStorage = () => {
        if (!isStorage(item)) {
            navigator("/signup", { replace: false });
        }
    }

    //엔터클릭이벤트 - 1.아이디입력, 2.비밀번호확인입력, 3.이메일인증, 4.휴대폰인증, 5.인증번호확인, 6.상호명, 7.주소입력, 8.영업시간, 9.정비인원, 10.정산계좌
    const onhandleOnKeyPress = (e, val) => {
        if (e.key == "Enter") {
            switch (val) {
                case 3: signupEmailCertSendAPI(); break;
                case 4: signupTelCertSendAPI(); break;
                case 5: verifyCertCheckAPI(); break;
                default: pageOnLoadHandler(val); break;
            }
        }
    }

    //페이지이동핸들러 - 0.약관동의, 1.아이디입력, 2.비밀번호입력, 3.이메일인증, 4.휴대폰인증, 5.인증번호확인, 6.상호명, 7.주소입력, 8.영업시간, 9.정비인원, 10.정산계좌
    const pageOnLoadHandler = (idx) => {
        let nav = "";
        switch (idx) {
            case 0:
                nav = "/signup/id";
                setStorage(item, {});
                break;
            case 1:
                nav = "/signup/password";
                updateStorage(item, "PARTNER_ID", signupRef.current[0].value);
                break;
            case 2:
                nav = "/signup/auth";
                updateStorage(item, "PASS_WD", signupRef.current[0].value);
                break;
            case 3:
                if (signupRef.current[0] !== undefined && signupRef.current[0].value.length > 0) {
                    nav = "/signup/auth/verify";
                    updateStorage(item, "TYPE", 1);
                    updateStorage(item, "ENCRYPT_VALUE", signupRef.current[0].value);
                }
                break;
            case 4:
                if (signupRef.current[0] !== undefined && signupRef.current[0].value.length > 0) {
                    nav = "/signup/auth/verify";
                    updateStorage(item, "TYPE", 3);
                    updateStorage(item, "ENCRYPT_VALUE", signupRef.current[0].value);
                }
                break;
            case 5:
                nav = "/signup/info/1";
                break;
            case 6:
                updateStorage(item, "PARTNER_NAME", signupRef.current[0].value);
                nav = "/signup/info/2";
                break;
            case 7:
                updateStorage(item, "ADDR1", addr.addr1);
                updateStorage(item, "ADDR2", signupRef.current[0].value);
                nav = "/signup/info/3";
                break;
            case 8:
                updateStorage(item, "START_BIZ_TIME", signupRef.current[0].value);
                updateStorage(item, "END_BIZ_TIME", signupRef.current[1].value);
                updateStorage(item, "START_BREAK_TIME", signupRef.current[2].value && signupRef.current[3].value ? signupRef.current[2].value : 0);
                updateStorage(item, "END_BREAK_TIME", signupRef.current[2].value && signupRef.current[3].value ? signupRef.current[3].value : 0);
                updateStorage(item, "HOLIDAY_OPER", signupRef.current[4].checked ? 1 : 0);
                nav = "/signup/info/4";
                break;
            case 9:
                updateStorage(item, "MECHANIC", (signupRef.current[0].value));
                nav = "/signup/info/5";
                break;
            case 10:
                updateStorage(item, "BANK_CD", signupRef.current[0].value);
                updateStorage(item, "ACCOUNT_NO", signupRef.current[1].value);
                nav = "/signup/info/6";
                break;
        }
        navigator(nav, { replace: false });
    }
    /* ===== 공통 ===== */

    /* ===== 이용약관 ===== */
    const terms = [
        { id: 0, title: termsTitle1, content: temrsContent1 },
        { id: 1, title: termsTitle2, content: temrsContent2 }
    ];
    const [checkItems, setCheckItems] = useState(false);
    //약관전체동의
    const checkboxCheckedAll = (checked, id) => {
        if (checked) {
            // const idArray = [];
            // terms.forEach((el) => idArray.push(el.id));
            // setCheckItems(idArray);
            id.current[1].checked = true;
            id.current[0].checked = true;
            setCheckItems(true);
            return;
        } else {
            id.current[1].checked = false;
            id.current[0].checked = false;
            setCheckItems(false);
            return;
            // setCheckItems([]);
        }
    }

    //약관개별동의
    const checkboxSingleChecked = (checked, id) => {
        if (id.current[0].checked == true && id.current[1].checked == true) {
            setCheckItems(true);
            return id.current[2].checked = true;
        }
        setCheckItems(false);
        return id.current[2].checked = false;
    }

    //자세히보기
    const [toggle, setToggle] = useState(false);
    const onHandleToggle = (id) => {
        setToggle({ ...toggle, [id]: !toggle[id] });
    };

    //약관동의 데이터끌어올리기
    const handleDataChange = (data) => {
        setCheckItems(data);
    };
    /* ===== 이용약관 ===== */

    /* ===== 아이디입력 ===== */
    //아이디유효성검사
    const validatedIdHandler = (e) => {
        if(e.target.value == "") {
            setValidated({ key: null, msg: '' });
        }
        const reg = /^[a-zA-Z0-9]{5,20}$/g;
        if (!reg.test(e.target.value)) {
            if (validated.msg != '영문 또는 숫자로만 입력 가능합니다') {
                setValidated({ key: true, msg: '영문 또는 숫자로만 입력 가능합니다' });
            }
            return;
        } else {
            duplicatedIdAPI(e.target.value);
        }
    }

    //아이디중복체크API
    const duplicatedIdAPI = async (value) => {
        let kval = true;
        let mval = '';

        try {
            const DATA = { "PARTNER_ID": value };
            const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerIdDuplicateCheckForPartner.do`, DATA);
            const res = response.data;
            if (res.message == "INVALID_REQUEST") { kval = true; mval = '잘못된 요청입니다'; }
            if (res.message == "INVALID_DATA") { kval = true; mval = '데이터가 존재하지 않습니다'; }
            if (res.message == "DUPLICATED_ID") { kval = true; mval = '이미 사용중인 아이디입니다'; }
            if (res.message == "SUCCESS") { kval = false; mval = '사용가능한 아이디 입니다'; }
        } catch (error) { console.log("Error >" + error); }
        if (validated.msg != mval) {
            setValidated({ key: kval, msg: mval });
        }
    }
    /* ===== 아이디입력 ===== */

    /* ===== 비밀번호입력 ===== */
    //비밀번호유효성검사
    const validatedPasswdHandler = (e) => {//val - 1.비밀번호입력, 2.비밀번호확인입력
        if(e.target.value == "") {
            setValidated({ key: null, msg: '' });
        }
        const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&^()-])[A-Za-z\d$@$!%*#?&^()-]{8,}$/g;
        const { name, value } = e.target;
        let kval = true;
        let mval = '';

        if (name == "passwd") {
            if (!reg.test(value)) {
                kval = true; mval = '비밀번호가 형식에 맞지않습니다';
            } else {
                if (value != signupRef.current[1].value) {
                    kval = true; mval = '비밀번호가 일치하지 않습니다';
                } else if (value == signupRef.current[1].value) {
                    kval = false; mval = '사용가능한 비밀번호 입니다';
                }
            }
        }
        if (name == "passwdChk") {
            if (!reg.test(value)) {
                kval = true; mval = '비밀번호가 형식에 맞지않습니다';
            } else {
                if (value != signupRef.current[0].value) {
                    kval = true; mval = '비밀번호가 일치하지 않습니다';
                } else if (value == signupRef.current[0].value) {
                    kval = false; mval = '사용가능한 비밀번호 입니다';
                }
            }
        }
        if(validated.msg != mval) {
            setValidated({ key: kval, msg: mval });
        }
    }
    /* ===== 비밀번호입력 ===== */

    /* ===== 이메일인증 ===== */
    //이메일 패턴 체크
    const [email, setEmail] = useState('');
    const emailForm = useRef();
    const validatedEmailHandler = (e) => {
        if(e.target.value == "") {
            setValidated({ key: null, msg: '' });
        }
        const reg = /^[A-Za-z0-9]*@[A-Za-z0-9]([-.]?[A-Za-z0-9])*\.[A-Za-z0-9]{2,3}$/;
        let kval = true;
        let mval = '';

        if (!reg.test(e.target.value)) {
            kval = true; mval = '이메일 형식이 적합하지 않습니다';
        } else {
            kval = false; mval = '등록가능한 이메일입니다';
        }
        if(validated.msg != mval){
            setValidated({ key: kval, msg: mval });
        }
    }

    //이메일인증코드전송
    const signupEmailCertSendAPI = async (type, email) => {//type-1.회원가입, 2.아이디찾기, 3.비밀번호찾기 
        const SUBJECT = "[두바키 파트너스] 고객님의 인증코드는 다음과 같습니다.";
        const formcontent = JSON.stringify(emailForm.current.innerHTML);
        const CONTENT = formcontent.replace(/\\/g, '').substring(1).replace(/"$/, '');

        const DATA = { "RECEIVER": email, "SUBJECT": SUBJECT, "CONTENT": CONTENT };
        const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerSignupEmailCertSendForPartner.do`, DATA);
        const res = response.data;
        if (res.message == 'SUCCESS') {
            switch (type) {
                case 1:
                    updateStorage(item, "CERT_CD", res.data.CODE);
                    pageOnLoadHandler(3);
                    break;
                case 2:
                    updateStorage("findId", "CERT_CD", res.data.CODE);
                    navigator("/find/verify");
                    break;
                case 3:
                    updateStorage("findPw", "CERT_CD", res.data.CODE);
                    navigator("/find/verify");
                    break;
            }
        }
    }
    /* ===== 이메일인증 ===== */

    /* ===== 휴대번호인증 ===== */
    const [telNo, setTelNo] = useState('');
    //휴대폰 패턴 체크
    const validatedTelHandler = (e) => {
        if(e.target.value == "") {
            setValidated({ key: null, msg: '' });
        }
        const reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        let kval = true;
        let mval = '';

        if (!reg.test(e.target.value)) {
            kval = true; mval = '전화번호 형식이 적합하지 않습니다';
        } else {
            kval = false; mval = '등록가능한 전화번호입니다';
        }
        if(validated.msg != mval){
            setValidated({ key: kval, msg: mval });
        }
    }

    //휴대폰인증코드전송
    const signupTelCertSendAPI = async (hTelNo) => {
        const DATA = { "RECEIVER": signupRef.current[0] !== undefined ? signupRef.current[0].value : hTelNo };
        const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerSignupTelCertSendForPartner.do`, DATA);
        const res = response.data;
        if (res.message == 'SUCCESS') {
            updateStorage(item, "CERT_CD", res.data.CODE);
            pageOnLoadHandler(4);
        }
    }
    /* ===== 휴대번호인증 ===== */

    /* ===== 인증번호확인 ===== */
    const verifyMap = [1, 2, 3, 4, 5];
    const [verify, setVerify] = useState({ verify0: '', verify1: '', verify2: '', verify3: '', verify4: '', });
    const verifyRef = useRef([]);
    //회원가입 - 인풋체인지저장핸들러
    const verifyInputChangeHandler = (e, idx) => {
        const { name, value } = e.target;
        const key = name.replace('verify', '');

        //포커스이동
        if (idx < 4) { if (value != "" && key == idx) { verifyRef.current[(idx + 1)].focus(); } }
        if (idx > 0) { if (value == "" && key == idx) { verifyRef.current[(idx - 1)].focus(); } }

        if(verifyRef.current[0].value != "" && verifyRef.current[1].value != "" && verifyRef.current[2].value != "" && verifyRef.current[3].value != "" && verifyRef.current[4].value != "") {
            setValidated({ key: false, msg: '' });
            return;
        }else{
            if (validated.key == false){
                setValidated({ key: true, msg: '' });
                return;
            }
        }
    }

    //인증번호확인API
    const verifyCertCheckAPI = async (type) => {//type -1.회원가입, 2.아이디찾기, 3.비밀번호찾기
        let CERT_CD = null;
        let CERT_VALUE = '';

        switch (type) {
            case 1: CERT_CD = getStorage(item).CERT_CD; break;
            case 2: CERT_CD = getStorage('findId').CERT_CD; break;
            case 3: CERT_CD = getStorage('findPw').CERT_CD; break;
        }

        if(verifyRef.current[0].value == "" || verifyRef.current[1].value == "" || verifyRef.current[2].value == "" || verifyRef.current[3].value == "" || verifyRef.current[4].value == "") {
            setValidated({ key: true, msg: '' });
            return;
        }
        CERT_VALUE = verifyRef.current[0].value + verifyRef.current[1].value + verifyRef.current[2].value + verifyRef.current[3].value + verifyRef.current[4].value;
        const DATA = { "CERT_VALUE": CERT_VALUE, "CERT_CD": CERT_CD };
        const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerCertCheckForPartneer.do`, DATA);
        const res = response.data;
        if (res.message == "UNAUTHORIZED_KEY") { alert("인증되지 않은 시크릿 키 혹은 클라이언트 키 입니다."); return; }
        if (res.message == "NOT_MATCH_KEY") { alert("인증코드가 불일치 합니다."); return; }
        if (res.message == "NOT_FOUND_UNAUTHORIZED_SESSION") { alert("인증 유효만료기간을 초과했습니다."); return; }
        if (res.message == "SUCCESS") {
            switch (type) {
                case 1: pageOnLoadHandler(5); break;
                case 2: clearStorage(); navigator("/find/id/success"); break;
                case 3: clearStorage(); navigator("/find/passwd/reset"); break;
            }
        }
    }

    //인증번호재전송
    const [isLocked, setLock] = useState(false);
    const [verifyCnt, setVerifyCnt] = useState(1);
    const signupCertReSendHandler = (e) => {
        if (!isLocked) {
            setLock(true);
            if (location.pathname == "/signup/auth/verify") {
                if (verifyCnt < 5) {
                    const TYPE = getStorage(item).TYPE;//1.이메일전송, 2.휴대폰전송
                    const ENCRYPT_VALUE = getStorage(item).ENCRYPT_VALUE;
                    if (TYPE == 1) { 
                        // setEmail(ENCRYPT_VALUE); 
                        signupEmailCertSendAPI(1, ENCRYPT_VALUE); }
                    if (TYPE == 3) { 
                        // setTelNo(ENCRYPT_VALUE); 
                        signupTelCertSendAPI(ENCRYPT_VALUE); }
                    setVerifyCnt(verifyCnt + 1);
                } else {
                    clearStorage();
                    navigator("/signup");
                }
            }
            if (location.pathname == "/find/verify") {
                if (verifyCnt < 5) {
                    if (isStorage("findId")) {
                        signupEmailCertSendAPI(2, getStorage("findId").EMAIL_ADDR);
                    }
                    if (isStorage("findPw")) {
                        signupEmailCertSendAPI(3, getStorage("findPw").EMAIL_ADDR);
                    }
                    setVerifyCnt(verifyCnt + 1);
                } else {
                    if (isStorage("findId")) {
                        clearStorage();
                        navigator("/find/id", { replace: true });
                    }
                    if (isStorage("findPw")) {
                        clearStorage();
                        navigator("/find/pw", { replace: true });
                    }
                }
            }
            setTimeout(function () { setLock(false); }, 3000);
        }
    }
    /* ===== 인증번호확인 ===== */

    /* ===== 상호명입력 ===== */
    const validatedNameHandler = (e) => {
        if(e.target.value == "") {
            setValidated({ key: null, msg: '' });
            return;
        }
        const { value } = e.target;

        let kval = true;
        let mval = '';
        if (value.length > 0) {
            kval = false; mval = '등록이 가능합니다';
        } else {
            kval = true; mval = '표준 한글 또는 영문으로 입력 가능합니다';
        }
        if(validated.msg != mval){
            setValidated({ key: kval, msg: mval });
        }
    }
    /* ===== 상호명입력 ===== */

    /* ===== 주소입력 ===== */
    const [isOpen, setOpen] = useState(false);//주소검색팝업오픈여부
    const [addr, setAddr] = useState({ addr1: ''});//주소검색isOpen
    const postcodeOpenHandler = {
        clickButton: () => { setOpen(current => !current); },// 버튼 클릭 이벤트
        selectAddress: (data) => {// 주소 선택 이벤트
            setAddr({ ...addr, addr1: data.roadAddress });
            setOpen(false);
            setValidated({ key: false, msg: '' });
        },
    }

    /* ===== 주소입력 ===== */

    /* ===== 영업시간선택 ===== */
    //영업시간선택
    const changeTimeHandler = (name, e) => {
        if (validated.key == true || validated.key == null){
            if (name == "open"){
                if (signupRef.current[1].value) {
                    setValidated({ key: false, msg: '' });
                    return;
                }
            }
            if (name == "close"){
                if (signupRef.current[0].value) {
                    setValidated({ key: false, msg: '' });
                    return;
                }
            }
        }
    }

    /* ===== 영업시간선택 ===== */

    /* ===== 정비인원선택 ===== */
    //영업시간선택
    const changeMechanicHandler = (name, value) => {
        if (validated.key == true || validated.key == null){
            setValidated({ key: false, msg: '' });
        }
    }
    /* ===== 정비인원선택 ===== */

    /* ===== 정산계좌입력 ===== */
    //은행코드리스트API
    const bankListAPI = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_HOST}bankMasterListForJson.do`);
            const res = response.data;
            if (res.message == "SUCCESS") { setOpt(res.data); }
        } catch (err) { console.log("Error >>", err); }
    }

    //은행코드선택
    const changeBankHandler = () => {
        if (signupRef.current[1].value != "") {
            setValidated({ key: false, msg: '해당 계좌정보로 정산처리 됩니다' });
                return;
        }
    }

    //계좌번호유효성체크
    const validatedAccountNoHandler = (e) => {
        if (signupRef.current[0].value > 0 && e.target.value != ""){
            if (validated.msg != '해당 계좌정보로 정산처리 됩니다'){
                setValidated({ key: false, msg: '해당 계좌정보로 정산처리 됩니다' });
                return;
            }
            return;
        }
        if (e.target.value != ""){
            if(validated.msg != '은행코드, 계좌번호를 확인바랍니다'){
                setValidated({ key: true, msg: '은행코드, 계좌번호를 확인바랍니다' });
                return;
        }
    }
        if (e.target.value == ""){
            setValidated({ key: null, msg: '' });
            return;
        }
    }

    /* ===== 정산계좌입력 ===== */

    /* ===== 이미지등록 ===== */
    const [files1, setFiles1] = useState([]);//사업자등록증이미지
    const [files2, setFiles2] = useState([]);//업체외관이미지

    //파일선택데이터끌어올리기
    const handleFile1Change = (data) => {
        setFiles1(data);
        //setCheckItems(data);
    };
    const handleFile2Change = (data) => {
        setFiles2(data);
    };
    /* ===== 이미지등록 ===== */

    //회원가입API
    const isLock = useRef(false);
    const [ isSubmit, setSubmit ] = useState(false);
    const partnerMasterRegAPI = async () => {
        if (isLock.current) {return;}
        isLock.current = true;
        setSubmit(true);
        const DATA = getStorage(item);
        const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerMasterRegForPartner.do`, DATA);
        const res = response.data;
        regResponseException(res);
        setTimeout(function() { isLock.current = false;setSubmit(false);}, 3000);
    }
    //회원가입예외처리
    const regResponseException = (res) => {//t - 1.회원가입, 2.이미지등록
        let msg = "";
        if (res.message == "INVALID_DATA") { msg = "데이터가 잘못되었습니다."; }
        if (res.message == "INVALID_REQUEST") { msg = "잘못된 요청입니다"; }
        if (res.message == "FAILED_DB_PROCESSING") { msg = "잘못된 요청 값으로 처리 중 DB 에러가 발생했습니다."; }
        if (res.message == "SIGNUP_FAIL") { msg = "사용자 인증에 실패하였습니다."; }
        if (msg != "") {
            clearStorage();
            navigator("/signup/fail");
        }

        if (res.message == "SUCCESS") {
            partnerImgRegAPI(res.data.PARTNER_CD, 1);
            // partnerImgRegAPI(res.data.PARTNER_CD, 2);
        }else{setSubmit(false); }
    }

    //업체이미지등록API
    const partnerImgRegAPI = async (cd, t) => {//type 1. 센터이미지 2.사업자등록증
        const HEADER = { headers: { 'Content-Type': 'multipart/form-data', charset: 'utf-8' } };
        const DATA = new FormData();
        const variables = [JSON.stringify({ PARTNER_CD: cd })];
        for (let f = 0; f < 2; f++) {
            if (f == 0) {
                for (let i = 0; i < files2.length; i++) { DATA.append('file', files2[i].file); }
            }
            if (f == 1) {
                for (let i = 0; i < files1.length; i++) { DATA.append('file', files1[i].file); }
            }
        }
        DATA.append('requestDTO', new Blob(variables), { "Content-Type": "application/json" });
        const response = await axios.post(`${process.env.REACT_APP_BACK_HOST}partnerCertImgRegForPartner.do`, DATA, HEADER);
        const res = response.data;
        if (res.message != "") {
            clearStorage();
            navigator("/signup/success");
            setSubmit(false);
        }
    }

    return {
        checkedStorage, onhandleOnKeyPress, pageOnLoadHandler, validated, signupRef//공통
        , checkboxCheckedAll, checkboxSingleChecked, onHandleToggle, handleDataChange, terms, checkItems, setCheckItems, toggle//이용약관
        , validatedIdHandler//아이디입력
        , validatedPasswdHandler//비밀번호입력
        , validatedEmailHandler, emailForm, email, signupEmailCertSendAPI//이메일인증
        , telNo, validatedTelHandler, signupTelCertSendAPI//휴대폰인증
        , verifyInputChangeHandler, verifyMap, verifyRef, verify, signupCertReSendHandler, verifyCertCheckAPI, verifyCnt//인증번호확인
        , validatedNameHandler//상호명입력
        , postcodeOpenHandler, isOpen, addr//주소입력
        , changeTimeHandler//영업시간선택
        , changeMechanicHandler//정비인원선택
        , bankListAPI, changeBankHandler, validatedAccountNoHandler//정산계좌입력
        , handleFile1Change, handleFile2Change//이미지등록
        , partnerMasterRegAPI, files1, files2, isSubmit, setSubmit//회원가입
    }
}

export default useUserHook;