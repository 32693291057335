import React, { useEffect } from 'react';
//style
import styles from 'src/asset/scss/style/userComn.module.scss';
//select
import SelectSearch from 'react-select-search';
import 'src/asset/scss/plugin/select.scss';
//Store
import useComnStore from 'src/webapp/stores/comn.store';
//Hook
import useComnHook from 'src/webapp/hooks/comn/comn.hook';
import useSignupHook from 'src/webapp/hooks/user/signup.hook';
//Comp
import _titleArea from '../../_titleArea';
import __step from './step';

export default function Step03() {
	//Store
	const { opt } = useComnStore();
	//Hook
	const { timeListAPI } = useComnHook();
	const { checkedStorage //스토리지유무확인
		, changeTimeHandler//영업시간선택
		, pageOnLoadHandler //버튼클릭
		, validated //유효성확인객체useState
		, signupRef//회원가입Ref
	} = useSignupHook();
	//useEffect
	useEffect(() => {
		checkedStorage();//스토리지유무확인
		timeListAPI();//시간리스트API
	}, []);

	return (
		<>
			<section className={["container", styles.userContainer].join(" ")}>
				<div className={styles.wrapper}>
					<_titleArea usePrev={true} title={`업체 등록하기`} subtitle={`영업시간을 선택해주세요`} isMobile={true} />{/* 타이틀 */}
					<__step step03={true} idx={3} />{/* 스텝 */}

					{/* 셀렉트필드 */}
					<div className={["flickPanel", styles.inputArea].join(" ")}>
						<div className={styles.selectField}><label>영업시간</label>
							<div className={styles.dateFormat}>
								<div className="select">
									<SelectSearch onChange={(e, idx) => { changeTimeHandler("open", e); (signupRef.current[0].value = e) }}
										ref={el => (signupRef.current[0] = el)}
										options={opt} value={opt.value}
										placeholder='오픈 시간' />
								</div>
								<span>-</span>
								<div className="select">
									<SelectSearch onChange={(e, idx) => { changeTimeHandler("close", e); (signupRef.current[1].value = e) }}
										ref={el => (signupRef.current[1] = el)}
										options={opt} value={opt.value}
										placeholder='마감 시간' />
								</div>
							</div>
						</div>
						<div className={styles.selectField}><label>휴게시간</label>
							<div className={styles.dateFormat}>
								<div className="select">
									<SelectSearch onChange={(e, idx) => { (signupRef.current[2].value = e) }}
										ref={el => (signupRef.current[2] = el)}
										options={opt} value={opt.value}
										placeholder='휴게시간시작' />
								</div>
								<span>-</span>
								<div className="select">
									<SelectSearch onChange={(e, idx) => { (signupRef.current[3].value = e) }}
										ref={el => (signupRef.current[3] = el)}
										options={opt} value={opt.value}
										placeholder='휴게시간종료' />
								</div>
							</div>
						</div>
						{/* 체크박스 */}
						<div className={[styles.checkBoxArea, styles.checkBoxOnly].join(" ")}>
							<div className={styles.checkbox}>
								<input
									ref={el => (signupRef.current[4] = el)}
									type='checkbox' name="holiday" />
								<label>주말 및 공휴일 영업</label>
							</div>
						</div>
					</div>

					{/* 버튼 */}
					<div className={["fixedPanel", styles.btnArea].join(" ")}>
						<button onClick={() => pageOnLoadHandler(8)}
							disabled={validated.key == null ? true : validated.key ? true : false}
							className="submitButton">다음</button>
					</div>
				</div>
			</section>
		</>
	);
}