import React, { useCallback, useState, useEffect, Suspense, lazy } from 'react';
import { useLocation } from "react-router";
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//style
import styles from 'src/asset/scss/style/schedule.module.scss';
//Store
import useComnStore from 'src/webapp/stores/comn.store';
import usePartnerStore from 'src/webapp/stores/partner.store';
//Hook
import useDatepickerHook from 'src/webapp/hooks/component/datepicker.hook';
import useScheduleHook from 'src/webapp/hooks/schedule/schedule.hook';
//Comp
import Spinner from 'src/webapp/views/component/loading/Spinner';
import NavBar from 'src/webapp/views/component/NavBar';
import TitleBar from 'src/webapp/views/component/TitleBar';
import _status from './comp/status';//예약상태
const _days = lazy(() => import('./comp/days'), { ssr: false });//예약스케줄+14일
const _timeList = lazy(() => import('./comp/timeList'), { ssr: false });//예약시간리스트
const _scheduleList = lazy(() => import('./comp/scheduleList'), { ssr: false });//스케줄리스트
//bottomsheet
const _bsDatepicker = lazy(() => import('src/webapp/views/component/BsDatepicker'), { ssr: false });//라인차트

export default function Schedule() {
    //location
    const { state } = useLocation();
    //Store
    const { socket } = useComnStore();
    const { isState } = usePartnerStore();
    //Hook
    const { initDateHandler
        , endDate//선택된날짜useState 
        , dateIsOpen//날짜선택팝업useState
        , onDateOpenHandler//날짜선택팝업on
        , handleDatepickerChange//날짜선택팝업off
    } = useDatepickerHook();//날짜팝업
    const { scheduleTimeListAPI//날짜리스트API
        , scheduleDayListAPI//날짜별스케줄리스트API
        , mechanicCntAPI//스케쥴러-정비사인원API
        , after14DaysListAPI//D+14상태리스트API
        , excelExportHandler//엑셀다운로드
    } = useScheduleHook();
    //Promise
    //날짜별조회
    const [isPending, setPending] = useState(true);
    const [timeList, setTimeList] = useState([]);
    const [scheduleList, setScheduleList] = useState(null);
    const [mechanicCnt, setMechanicCnt] = useState(0);
    useEffect(() => {
        setPending(true);
        if (isState && endDate != false) {
            Promise.all([scheduleTimeListAPI(endDate), scheduleDayListAPI(endDate), mechanicCntAPI(endDate)]).then((value) => {
                setTimeList(value[0]);
                setScheduleList(value[1]);
                setMechanicCnt(value[2]);
                setPending(false);
            });
        }
    }, [isState, socket, endDate]);
    //D+14날짜조회
    const [afterDayList, setAfterDayList] = useState([]);
    useEffect(() => {
        if (isState) {
            Promise.all([after14DaysListAPI()]).then((value) => {
                setAfterDayList(value[0]);
            });
        }
    }, [isState, socket]);
    useEffect(() => {
        const endDate = state ?? 0;
        initDateHandler(0, endDate);//날짜초기셋팅(당일~당일)
    }, []);
    return (
        <>
            <section className="fixedPanel container main">
                <NavBar id={3} />{/* 메뉴바 */}
                <div className="layer">
                    <div className="fixedPanel">
                        <TitleBar title={`스케줄 관리`} />{/* 상단타이틀 */}
                        {/* 검색창 */}
                        <hr className="separate" />
                        <div className="searchArea">
                            <div className="dateArea">
                                <div className="datepicker">{(endDate.endDate ?? '')}</div>
                                <button onClick={(e) => onDateOpenHandler()}
                                    aria-label="calendar"
                                    className="btnCalendar"><FontAwesomeIcon icon={['fal', 'calendar-lines']} /></button>
                                <button onClick={() => excelExportHandler(endDate)}
                                    aria-label="excel"
                                    className="btnExcel" ><FontAwesomeIcon icon={['fal', 'arrow-down-to-line']} /></button>
                            </div>
                            <div><_status /></div>{/* 상태 */}
                        </div>
                    </div>
                    <div className="flickPanel">
                        {isPending ? <div className="fullHeight"><Spinner isPending={true} /></div>
                            : <div className={styles.scheduleContainer}>
                                {scheduleList && scheduleList.length > 0 ?
                                    <>
                                        <Suspense fallback={null}><_timeList list={timeList} /></Suspense>{/* 날짜리스트 */}
                                        <Suspense fallback={null}><_scheduleList list={scheduleList} cnt={mechanicCnt} endDate={endDate} /></Suspense>{/* 스케줄리스트 */}
                                    </>
                                    : <div className={["isEmpty"].join(" ")}>
                                        <FontAwesomeIcon icon={['fat', 'circle-exclamation']} /><span>해당날짜의 예약이 활성화되지 않았습니다.</span>
                                    </div>

                                }
                            </div>
                        }
                    </div>
                    <div className="fixedPanel"><Suspense fallback={null}><_days list={afterDayList} onDate={initDateHandler} /></Suspense>{/* D+14리스트 */}</div>
                </div>
            </section>

            {/* bottomsheet */}
            {dateIsOpen ? <Suspense fallback={null}><_bsDatepicker isOpen={dateIsOpen} onDismiss={handleDatepickerChange} /></Suspense> : null}
        </>
    );
}